import React, { Fragment } from "react";
import * as Survey from "survey-react";
import Auth from "../../Auth";
import {
  baseURL,
  endpoints,
  httpGET,
  httpPOST,
  httpDELETE,
  httpPUT,
  httpGETByArray,
  acceptedFileTypes,
  saveFrequency,
  appInsightsCode,
  ocrDocumentsSurveyTag,
  ocrDocumentTargetFolder,
  investmentsOcrDocumentsSurveyTag,
  investmentsOcrDocumentTargetFolder
} from "../../api";
import NavControlsGenerator from "../utilities/NavControlsGenerator";
import SessionStoreManager from "../utilities/SessionStoreManager";
import Constants from "../utilities/Constants";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from "react-ga";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
/**
 * This component renders a questionnaire (using the Survey js component) for the given customer along with any previously recorded responses.
 * The user can save at any pont and submit their completed survey after they have completed all mandatory questions.
 * @returns {jsx} the survey component or a loading message
 */
class YourTaxInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionnaire: {},
      questionTileResponse: {},
      isLoading: true,
      customerid: undefined,
      responsesid: null,
      responses: {},
      savebuttontext: "Save",
      isCompleted: false,
      currentPage: undefined,
      isSaving: false,
      isUploading: false,
      intervalId: undefined,
      ToBeProcessedByRPA: "",
      questionnaireNav: undefined,
      sectionNavHeaderClassName: "sectionNavHeader_collapsed",
      tier: undefined,
      isSubmitting: false,      
    };
    this.handleSave = this.handleSave.bind(this);
    this.baseURL = baseURL;
    this.onValueChanged = this.onValueChanged.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.questionnaireVersionArr = [];
    this.onPartialSend = this.onPartialSend.bind(this);
    this.onUploadFiles = this.onUploadFiles.bind(this);
    this.onClearFiles = this.onClearFiles.bind(this);
    this.saveInterval = this.saveInterval.bind(this);
    this.backToIncomeSources = this.backToIncomeSources.bind(this);
    this.onCurrentPageChanging = this.onCurrentPageChanging.bind(this);
    this.onAfterRenderSurvey = this.onAfterRenderSurvey.bind(this);
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode
      }
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();
    const auth = new Auth();
    if (
      SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey) &&
      SessionStoreManager.getValueByKey(Constants.navRulesKey) &&
      SessionStoreManager.getValueByKey(Constants.navNodesKey) &&
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
    ) {
      var currentUser = SessionStoreManager.getValueByKey(
        Constants.currentUserCosmosDBKey
      );
      this.setState({
        customerid: currentUser.id
      });
      this.LoadResponse(auth);
    }
    var intervalId = setInterval(this.saveInterval, saveFrequency);
    this.setState({ intervalId: intervalId });
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }
  saveInterval() {
    try {
      if (this.state.isCompleted === false) {
        this.upsertResponses(false);
      }
    } catch (error) {
      alert("Error saving response");
    }
  }
  async LoadResponse(auth) {
    
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    //Return the questionnaire from the user's process
    var version = currentTaxProcess.questionnaireVersion;
    var parameters =
      currentTaxProcess.taxYearId + "/" + currentUser.id + "/" + version;

    let response = await httpGET(
      endpoints.filter(endpoint => endpoint.name === "GetResponse")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      try {
        var responsedata = JSON.parse(response.body.responsesJson);
        this.setState({
          responses: responsedata.data,
          responsesid: response.body.id,
          questionnaireversion: response.body.questionnaireVersion,
          currentPage: responsedata.currentPageNo,
          isCompleted: responsedata.isCompleted,
          tier: response.body.tier
        });
      }
      catch(err) {
        this.setState({
          responses: {},
          responsesid: response.body.id,
          questionnaireversion: response.body.questionnaireVersion
        });
      }
      

    
      this.LoadQuestionnaire(auth);
    }
    if (response && response.status === 204) {
      //none stored for this user so use default questionnaire version
      this.setState({
        questionnaireversion: version,
        tier: currentTaxProcess.tier
      });
      this.LoadQuestionnaire(auth);
    }
  }
  async LoadQuestionnaire(auth) {
    if (this.state.questionnaireversion) {
      var currentTaxProcess = SessionStoreManager.getValueByKey(
        Constants.taxYearProcessKey
      );
      var parametersQuestionnaire =
        currentTaxProcess.taxYearId + "/" + this.state.questionnaireversion;

      let response = await httpGET(
        endpoints.filter(endpoint => endpoint.name === "GetQuestionnaire")[0],
        parametersQuestionnaire,
        await auth.getToken(),
        true
      );

      if (response && response.status === 200) {
        // load the correct nav

        this.setState({
        questionnaire: response.body.questionnaireJson,
        questionnaireNav: JSON.parse(response.body.questionnaireNav)

        });
        this.LoadFilterQuestionTiles(auth);
      }
    }
  }
  async LoadFilterQuestionTiles(auth) {
    if (this.state.questionnaireversion) {
      var fqTileIdArr = [];
      var currentTaxProcess = SessionStoreManager.getValueByKey(
        Constants.taxYearProcessKey
      );
      var currentUser = SessionStoreManager.getValueByKey(
        Constants.currentUserCosmosDBKey
      );
      var parameters = currentTaxProcess.taxYearId + "/" + currentUser.id;

      let response = await httpGET(
        endpoints.filter(
          endpoint =>
            endpoint.name ===
            "GetFilterQuestionTileResponseByTaxYearIdAndCustomerId"
        )[0],
        parameters,
        await auth.getToken(),
        true
      );

      if (response && response.status === 200) {
        response.body.forEach(e => {
          fqTileIdArr.push(e.filterQuestionTileId);
          this.LoadQuestionTileQuestionnaireVersion(
            e.filterQuestionTileId,
            auth,
            fqTileIdArr
          );
        });
        this.LoadIncomeSourcesToQuestionnaire(
          currentTaxProcess.questionnaireVersion,
          auth,
          fqTileIdArr,
          this.state.tier
        );
        }
    }
  }

  async LoadQuestionTileQuestionnaireVersion(filterQuestionTileId, auth) {
    let questionnaireVersion = await httpGET(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetFilterQuestionTileQuestionnaireVersionByFilterQuestionTileId"
      )[0],
      filterQuestionTileId,
      await auth.getToken(),
      true
    );
    if (questionnaireVersion && questionnaireVersion.status === 200) {
      this.questionnaireVersionArr.push(
        questionnaireVersion.body[0].questionnaireVersion
      );
    }
  }

  async LoadIncomeSourcesToQuestionnaire(
    questionnaireVersion,
    auth,
    filterQuestionTileId,
    tier
  ) {
    var concat = "str='";
    var count = 0;
    filterQuestionTileId.forEach(e => {
      if (count < filterQuestionTileId.length - 1) {
        concat += e + "'&str='";
      } else if (count === filterQuestionTileId.length - 1) {
        concat += e + "'";
      }
      count++;
    });
    // console.log(concat);
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    var parameters =
      concat +
      "&taxYearId='" +
      currentTaxProcess.taxYearId +
      "'&questionnaireVersion='" +
      questionnaireVersion +
      "'" +
      "'&tier='" +
      tier +
      "'";

    let response = await httpGETByArray(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetIncomeSourcesToQuestionnaireByFilterQuestionTileIdTaxYearIdAndquestionnaireVersion"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      
        this.setState({
        questionTileResponse: response.body,
        isLoading: false
      });
      
      
    }
  }

 
  handleSave(event) {
    this.setState({
      isSaving: true
    });

    try {
      this.setState({
        isSaving: true
      });
      this.upsertResponses(false);
      this.setState({
        isSaving: false
      });
    } catch (error) {
      this.setState({
        isSaving: false
      });
      alert("Error saving response");
    }
  }
  async upsertResponses(isCompletion) {
    //var results = this.state.responses;
    var results = "";
    if (isCompletion) {
      results = {
        currentPageNo: "1",
        data: this.state.responses,
        isCompleted: isCompletion
      };
    } else {
      results = {
        currentPageNo: this.state.currentPage,
        data: this.state.responses,
        isCompleted: isCompletion
      };
    }

    var resultsToPost;
    const auth = new Auth();
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );

    if (!this.state.responsesid) {
      resultsToPost = {
        taxYearId: currentTaxProcess.taxYearId,
        customerId: this.state.customerid,
        questionnaireVersion: this.state.questionnaireversion,        
        responsesJson: JSON.stringify(results)
      };
    } else {
      if (isCompletion) {
        resultsToPost = {
          id: this.state.responsesid,
          taxYearId: currentTaxProcess.taxYearId,
          customerId: this.state.customerid,
          questionnaireVersion: this.state.questionnaireversion,
          responsesJson: JSON.stringify(results),
          ToBeProcessedByRPA: "NO"
        };
      } else {
        resultsToPost = {
          id: this.state.responsesid,
          taxYearId: currentTaxProcess.taxYearId,
          customerId: this.state.customerid,
          questionnaireVersion: this.state.questionnaireversion,
          responsesJson: JSON.stringify(results)
        };
      }
    }
    

    let payload = resultsToPost;
    let response = await httpPOST(
      endpoints.filter(endpoint => endpoint.name === "UpsertResponse")[0],
      await auth.getToken(),
      payload,
      true
    );
    if (response && response.status === 200) {
      //console.log("UpsertResponse response: " + response.statusText);
      var responsedata = JSON.parse(response.body.responsesJson);
      this.setState({
        responses: responsedata.data,
        responsesid: response.body.id,
        isLoading: false,
        currentPage: responsedata.currentPageNo,
        isCompleted: responsedata.isCompleted,
        isSaving: false,
        ToBeProcessedByRPA: responsedata.ToBeProcessedByRPA

      });
      if (isCompletion) {
        //If the questionnaire is complete, we need to update the process
        //Questionnaire is finished
        var today = new Date();
        currentTaxProcess.questionnaireCompleted = today.toISOString();
        currentTaxProcess.status = "40";
        SessionStoreManager.setValueForKey(
          Constants.taxYearProcessKey,
          currentTaxProcess
        );
        await this.UpdateTaxYearProccess(auth, currentTaxProcess);
        await this.sendCompletionNotification(auth, currentTaxProcess.taxYearId, currentTaxProcess.customerId)
      }
      
    }
  }
  async upsertCompleted(survey) {
    
    var results = "";
   
      results = {
        currentPageNo: "1",
        data: survey.data,
        isCompleted: true
      };
      this.setState({
        isSubmitting: true
      });
    var resultsToPost;
    const auth = new Auth();
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );

    resultsToPost = {
      id: this.state.responsesid,
      taxYearId: currentTaxProcess.taxYearId,
      customerId: this.state.customerid,
      questionnaireVersion: this.state.questionnaireversion,
      responsesJson: JSON.stringify(results),
      ToBeProcessedByRPA: "NO"
    };


       
     
   

    let payload = resultsToPost;
    let response = await httpPOST(
      endpoints.filter(endpoint => endpoint.name === "UpsertResponse")[0],
      await auth.getToken(),
      payload,
      true
    );
    if (response && response.status === 200) {
      //console.log("UpsertResponse response: " + response.statusText);
      var responsedata = JSON.parse(response.body.responsesJson);
      this.setState({
        responses: responsedata.data,
        responsesid: response.body.id,
        isLoading: false,
        currentPage: responsedata.currentPageNo,
        isCompleted: responsedata.isCompleted,
        isSaving: false,
        ToBeProcessedByRPA: responsedata.ToBeProcessedByRPA

      });
     
      var today = new Date();
      currentTaxProcess.questionnaireCompleted = today.toISOString();
      currentTaxProcess.status = "40";
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        currentTaxProcess
      );
      await this.UpdateTaxYearProccess(auth, currentTaxProcess);
      await this.sendCompletionNotification(auth, currentTaxProcess.taxYearId, currentTaxProcess.customerId)
      
      
    }
    this.setState({
      isSubmitting: false
    });
  }
  async sendCompletionNotification(auth, taxYearId, customerId)
  {
    let payload =  {
      TaxYearId: taxYearId,
      customerId: customerId
    };
    let detailstoPost = await httpPOST(
      endpoints.filter(endpoint => endpoint.name === "SendCompletionNotification")[0],
      await auth.getToken(),
      payload,
      true
    );
  }
  async UpdateTaxYearProccess(auth, currentTaxProcess) {
    let response = await httpPUT(
      endpoints.filter(
        endpoint => endpoint.name === "GetOrCreateTaxYearProcess"
      )[0],
      currentTaxProcess.id,
      await auth.getToken(),
      currentTaxProcess
    );
    if (response && response.status === 200) {

    }
  }
  
  onComplete(survey) {
    try {
      survey.clearInvisibleValues = true;
      survey.isCompleted = true;
      this.setState({
        responses: survey.data
      });
      this.upsertCompleted(survey);
      
    } catch (error) {
      survey.isCompleted = false;
      this.setState({
        isCompleted: false,
        isSubmitting: false
      });
      alert("Error submitting survey");
    }
  }
  onValueChanged(survey, _options) {
    survey.clearInvisibleValues = true;
    this.setState({
      responses: survey.data,
      currentPage: survey.currentPage.name,
      isCompleted: survey.isCompleted
    });
  }
  async onAfterRenderSurvey(survey,options){
    var currentPageName = survey.currentPage.name;
    var currentPageIndex = survey.pages.findIndex(x => x.name == currentPageName);
    this.highlightPage(survey,currentPageIndex);
  }

  async highlightPage(survey,pageIndex){
    if (document.getElementById(survey.pages[pageIndex].name)) 
    {
      var highlightedSpan = document.getElementsByClassName("scrollHighlightText")[0];
      if (highlightedSpan)
      {
        highlightedSpan.classList.remove("scrollHighlightText");
        highlightedSpan.parentElement.classList.remove("scrollHighlightDiv")
      }
      document.getElementById(survey.pages[pageIndex].name).classList.add("scrollHighlightText");
      document.getElementById(survey.pages[pageIndex].name).parentElement.classList.add("scrollHighlightDiv")
    }
    else
    {
      var foundSection = Constants.pageSections.find(x => x.pageName == survey.pages[pageIndex].name);
      if(foundSection) {
      var highlightedSpan = document.getElementsByClassName("scrollHighlightText")[0];
      if (highlightedSpan)
      {
        highlightedSpan.classList.remove("scrollHighlightText");
        highlightedSpan.parentElement.classList.remove("scrollHighlightDiv")
      }
      document.getElementById(foundSection.section).classList.add("scrollHighlightText");
      document.getElementById(foundSection.section).parentElement.classList.add("scrollHighlightDiv")
      }
    }
  }

  async onCurrentPageChanging(survey,options){
    var oldPageName = options.oldCurrentPage.name;
    var newPageName = options.newCurrentPage.name;
    var oldPageIndex = survey.pages.findIndex(x => x.name == oldPageName);
    var newPageIndex = survey.pages.findIndex(x => x.name == newPageName);
    var navigatingForward = true;
    var nextPage = newPageIndex;
    if (oldPageIndex > newPageIndex)
    {
      navigatingForward = false;
    }
    if(navigatingForward){
      if(options.oldCurrentPage.hasErrors(true, false)){
        options.allowChanging=false;
      }
      else{
        this.highlightPage(survey,nextPage)
      }
    }
    else{
      this.highlightPage(survey,nextPage)
    }
  }

  onPartialSend(event) {
    this.setState({
      isSaving: true
    });

    try {
      this.upsertResponses(false);
      this.setState({
        isSaving: false
      });
    } catch (error) {
      this.setState({
        isSaving: false
      });
      alert("Error saving survey: " + error.value);
    }
  }
  fileTypeValidation(file) {
    var validExts = acceptedFileTypes;
    var extension = file.name.split(".").pop();

    if (validExts.includes(extension)) {
      return true;
    } else {
      var type = file.type;
      if (type.includes("image") || type === "text/plain") {
        return true;
      } else {
        return false;
      }
    }
  }
  async getExistingBlobs(auth) {
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters =
      currentUser.id +
      "/ReturnAllFiles/" +      
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId;

    let response = await httpGET(
      endpoints.filter(
        (endpoint) => endpoint.name === "GetAdditionalDocuments"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      return response.body
    }
    else
    {
      return null
    }
  }
  async fileNameDuplicateValidation(file, questionName) {
    const auth = new Auth();
    var i;
    var folderName = "";
    if (questionName.includes(investmentsOcrDocumentsSurveyTag))
    {
        folderName = investmentsOcrDocumentTargetFolder + "/";
    }
    else
    {
      if (questionName.includes(ocrDocumentsSurveyTag))
      {
          folderName = ocrDocumentTargetFolder + "/";
      }
    }

    //Get blobs for this questionnaire
    var existingAttachments = await this.getExistingBlobs(auth)
  
   // return false;
   var duplicateFound = false;
    if (existingAttachments)
    {
      for (i = 0; i < existingAttachments.length; i++) {

        if (existingAttachments[i].FileName.toLowerCase() === folderName.toLowerCase() + file.toLowerCase()){
          duplicateFound = true;
        }
      }
     
    }
   
    return duplicateFound

   
  }
  clickNav(pageid, itemtitle){
    this.setState({
      currentPage: pageid,
      sectionNavHeaderClassName: "sectionNavHeader_collapsed"
   });
  }

  clickHeader(){
    if (this.state.sectionNavHeaderClassName === "sectionNavHeader_collapsed"){
      this.setState({
        sectionNavHeaderClassName: "sectionNavHeader_expanded"
     });
    }
    else
    {
      this.setState({
      sectionNavHeaderClassName: "sectionNavHeader_collapsed"
      });

    }
    
  }
  async onUploadFiles(survey, options) {
    var duplicateFiles = false
    this.setState({
      isUploading: true
    });
    try {
      const auth = new Auth();

      var i;

      for (i = 0; i < options.files.length; i++) {
        if (!this.fileTypeValidation(options.files[i])) {
          var extension = options.files[i].name.split(".").pop();
          alert("Files with the extension " + extension + " are not accepted");
        } else {
          duplicateFiles =  await this.fileNameDuplicateValidation(options.files[i].name,options.name)
          if(duplicateFiles)
          {
            alert("A file with the name '" + options.files[i].name + "' has already been attached to this questionnaire please rename your file and try again" );
          }
         else
         {
          // We need to create closure with the help of IIFE statement, otherwise options.files[i] when we access it inside addEventListener will always point to the last file in for loop.
           //SILVER TIER -- Need to check if the question is a silver question (Has SILVER in the name) str.includes('SILVER')
            //If it does, the file should be uploaded into an ABBYY folder
           
            //If not it should be sent to the normal location.
     
            (function(file) {
            var reader = new FileReader();
           
            // This will be called when the file content is extracted from the File object using FileReader
            reader.addEventListener(
              "load",
              async function() {
                var blobPayload;
                if (options.name.includes(investmentsOcrDocumentsSurveyTag))
                {
                  blobPayload = {
                    // Container name is always customer ID
                    containerName: SessionStoreManager.getValueByKey(
                      Constants.taxYearProcessKey
                    ).customerId,
                    blobName: file.name,
                    base64FileContent: this.result.split(",")[1],
                    taxYearId: SessionStoreManager.getValueByKey(
                      Constants.taxYearProcessKey
                    ).taxYearId,
                    folder: investmentsOcrDocumentTargetFolder
                  };
                }
                else
                {
                  if (options.name.includes(ocrDocumentsSurveyTag))
                  {
                    blobPayload = {
                      // Container name is always customer ID
                      containerName: SessionStoreManager.getValueByKey(
                        Constants.taxYearProcessKey
                      ).customerId,
                      blobName: file.name,
                      base64FileContent: this.result.split(",")[1],
                      taxYearId: SessionStoreManager.getValueByKey(
                        Constants.taxYearProcessKey
                      ).taxYearId,
                      folder: ocrDocumentTargetFolder
                    };
                  }
                  else
                  {
                    blobPayload = {
                      // Container name is always customer ID
                      containerName: SessionStoreManager.getValueByKey(
                        Constants.taxYearProcessKey
                      ).customerId,
                      blobName: file.name,
                      base64FileContent: this.result.split(",")[1],
                      taxYearId: SessionStoreManager.getValueByKey(
                        Constants.taxYearProcessKey
                      ).taxYearId
                    };
                  }
                  
                }
                

                let response = await httpPOST(
                  endpoints.filter(
                    endpoint =>
                      endpoint.name === "UploadAttachmentToBlobContainer"
                  )[0],
                  await auth.getToken(),
                  blobPayload,
                  false
                );
                if (response && response.status === 200) {
                  var callbackData = {
                    file: file,
                    content: response.body
                  };
                  options.callback("success", [callbackData]);
                }

                //Upload files to blob
              },
              false
            );

            reader.readAsDataURL(file);
          })(options.files[i]);
         
          
        }
        }
      
      }
      this.setState({
        isUploading: false
      });
    
    } catch (error) {
      this.setState({
        isUploading: false
      });
    }
  }


  async onClearFiles(survey, options) {
    const auth = new Auth();
    var parametersAttachment = "";
    // Single file in array of files

    if (options.fileName) {
      var i;
      for (i = 0; i < options.value.length; i++) {
        if (options.value[i].name === options.fileName) {
          if (options.name.includes(investmentsOcrDocumentsSurveyTag)){
            parametersAttachment =
            SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
              .customerId +
            "/" +
            options.fileName +
            "/" +
            SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
              .taxYearId +
              "/" +
              investmentsOcrDocumentTargetFolder;
          }
          else
          {
            if (options.name.includes(ocrDocumentsSurveyTag)){
              parametersAttachment =
              SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
                .customerId +
              "/" +
              options.fileName +
              "/" +
              SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
                .taxYearId +
                "/" +
                ocrDocumentTargetFolder;
            }
            else
            {
              parametersAttachment =
            SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
              .customerId +
            "/" +
            options.fileName +
            "/" +
            SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
              .taxYearId +
              "/" +
              "NONE";
            }
            
          }
          
          let response = await httpDELETE(
            endpoints.filter(
              endpoint => endpoint.name === "DeleteAttachmentFromBlobContainer"
            )[0],
            parametersAttachment,
            await auth.getToken(),
            true
          );

          if (response && response.status === 200) {
            options.callback("success");
          }
        }
      }
    }
    // All the files in the array
    else {
      var x;
      for (x = 0; x < options.value.length; x++) {
        if (options.name.includes(investmentsOcrDocumentsSurveyTag)){
          parametersAttachment =
          SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
            .customerId +
          "/" +
          options.value[x].name +
          "/" +
          SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
            .taxYearId +
            "/" +
            investmentsOcrDocumentTargetFolder;
        }
        else
        {
          if (options.name.includes(ocrDocumentsSurveyTag)){
            parametersAttachment =
            SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
              .customerId +
            "/" +
            options.value[x].name +
            "/" +
            SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
              .taxYearId +
              "/" +
              ocrDocumentTargetFolder;
          }
          else
          {
            parametersAttachment =
          SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
            .customerId +
          "/" +
          options.value[x].name +
          "/" +
          SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
            .taxYearId +
            "/" +
            "NONE";
          }
          
        }
        let response = await httpDELETE(
          endpoints.filter(
            endpoint => endpoint.name === "DeleteAttachmentFromBlobContainer"
          )[0],
          parametersAttachment,
          await auth.getToken(),
          true
        );

      
      }
      options.callback("success");
    }
  
  }

  onAfterRenderQuestion(survey, options) {
    /* if (options.question.getType() === 'file' && options.question.value && options.question.value !== '') { if (options.question.getType() === 'file' && options.question.value && options.question.value !== '') {
      //To do: generate HREF value dynamically rather than using content value
          options.htmlElement.innerHTML += '<a class="btn btn-xs btn-primary" href="' + options.question.value[0].content + '">Download"' + options.question.value[0].name + '" </a>';
      }
      } */
  }
  getClassName(pageId){
    if (pageId === this.state.currentPage)
    {
      return "menuItem active";
    }
    else
    {
      return "menuItem";
    }
  }
  backToIncomeSources(){
    this.resetTiles();
  }
  async resetTiles() {
    const auth = new Auth();
    let taxYearProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    let customerIdProcess = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );

    //Return the questionnaire from the user's process
    var version = taxYearProcess.questionnaireVersion;
    let taxyearid = taxYearProcess.taxYearId;
    let customerId = customerIdProcess.id;
    var parameters = taxyearid + "/" + customerId + "/" + version  + "/" + false;
    let response = await httpGET(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetFilterQuestionTileResponseByTaxYearIdAndCustomerId"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      //Move to tiles
      taxYearProcess.CurrentNavNodeCode = 200;
      this.UpdateTaxYearProccess(auth, taxYearProcess);
      this.props.history.push({
        pathname: "/tellusmore"
      });
    }
  }

  render() {
    var surveyToRender = "";
    var navigationToRender = "";
    var autoSaveText="";
    var page = null;
    if (!this.state.isLoading) {
      if (this.state.isCompleted) {
        if ((new Date().getMonth()) === 0 && ((new Date().getDate()) >= 4 && (new Date().getDate()) <= 31)) {
          if (!this.state.isSubmitting) {
            surveyToRender = (
              <Fragment>
                <article className="successPage">
                  <section className="successGraphic"></section>
                  <section className="successMessage">
                    <h2>Thank you</h2>
                    <h3>Your answers have been submitted</h3>
                    <p>Your tax return is being prepared and will be reviewed by your tax team. You'll receive an e-mail when your tax return is ready for you to review in <strong>your tax returns</strong>.</p>
                    <p className="smallPrint">As you are submitting your information after 4 January {(new Date().getFullYear())} we are unable to guarantee the preparation of your tax return by the 31 January {(new Date().getFullYear())} deadline.</p>
                    <NavControlsGenerator
                      currentPageNavCode={200}
                    ></NavControlsGenerator>
                  </section>
                </article>
              </Fragment>
            );
          }
          else
          {
            surveyToRender = (
              <Fragment>
                <article className="successPage">
                  <section className="successGraphic"></section>
                  <section className="successMessage">
                    <h2>Thank you</h2>
                    <h3>Your answers are being submitted...</h3>   
                  </section>
                </article>
              </Fragment>
            );
          }

        } else {
          if (!this.state.isSubmitting) {
            surveyToRender = (
              <Fragment>
                <article className="successPage">
                  <section className="successGraphic"></section>
                  <section className="successMessage">
                    <h2>Thank you</h2>
                    <h3>Your answers have been submitted</h3>
                    <p>Your tax return is being prepared and will be reviewed by your tax team. You'll receive an e-mail when your tax return is ready for you to review in <strong>your tax returns</strong>.</p>                        
                    <NavControlsGenerator
                      currentPageNavCode={200}
                    ></NavControlsGenerator>
                  </section>
                </article>
              </Fragment>
            );
          }
          else
          {
            surveyToRender = (
              <Fragment>
                <article className="successPage">
                  <section className="successGraphic"></section>
                  <section className="successMessage">
                    <h2>Thank you</h2>
                    <h3>Your answers are being submitted...</h3>   
                  </section>
                </article>
              </Fragment>
            );
          }
         
        }
      } else {
        var model = new Survey.Model(this.state.questionnaire);
    
        Survey.surveyLocalization.locales[
          Survey.surveyLocalization.defaultLocale
        ].cleanCaption = "Remove all";
       
        Survey.StylesManager.applyTheme("bootstrap");
      
        var myCss = {
          matrix: {
            root: "table table-striped"
          },
          navigationButton: "button btn-lg"
        };
        var navHTML = "";
        var navForTheQuestionnaire = this.state.questionnaireNav;
        window.survey = new Survey.Model(this.surveyJSON);
        if (Object.keys(this.state.questionTileResponse).length > 0) {
       
          this.state.questionTileResponse.forEach(element => {
          page = model.getPageByName(element.pageId);
          model.removePage(page);
      
         
          if (navForTheQuestionnaire !== undefined &&  navForTheQuestionnaire !== null){
             navForTheQuestionnaire = navForTheQuestionnaire.filter(function(Navpage) {
              return Navpage.pageId !== element.pageId;
            });
          }
        });

        if (navForTheQuestionnaire !== undefined &&  navForTheQuestionnaire !== null){
       
        navHTML = navForTheQuestionnaire.map((item, key) => (         
          <div key={key} className="menuItem">
            <span id={item.pageId} onClick={() => { this.clickNav(item.pageId, item.title)}}>{item.title}</span>
          </div>
        ));
        }
        if (navForTheQuestionnaire !== undefined &&  navForTheQuestionnaire !== null){
        navigationToRender = (
          <section className="sectionNav">
        <header className={this.state.sectionNavHeaderClassName}  onClick={() => { this.clickHeader()}}>
        <div className="sectionsIcon"></div>
          <h2>Questionnaire sections</h2>
        </header>
        
        <nav className="surveyNav">
         {navHTML}
          <div key="999" className="button editSources">
          <span onClick={() => { this.backToIncomeSources()}}>Review or add income sources</span>
        </div> 
        </nav>
        
      </section>

        );
        }
        }
        else
        {
          //All selected we need to show the whole nav bar
          if (navForTheQuestionnaire !== undefined &&  navForTheQuestionnaire !== null){
       
            navHTML = navForTheQuestionnaire.map((item, key) => (         
              <div key={key} className="menuItem">
                <span id={item.pageId} onClick={() => { this.clickNav(item.pageId, item.title)}}>{item.title}</span>
              </div>
              
            ));
            }
            if (navForTheQuestionnaire !== undefined &&  navForTheQuestionnaire !== null){
            navigationToRender = (
              <section className="sectionNav">
            <header className={this.state.sectionNavHeaderClassName}  onClick={() => { this.clickHeader()}}>
            <div className="sectionsIcon"></div>
              <h2>Questionnaire sections</h2>
            </header>
            <nav className="surveyNav">
             {navHTML}
              <div key="999" className="button editSources">
              <span onClick={() => { this.backToIncomeSources()}}>Review or add income sources</span>
            </div> 
            </nav>
            
          </section>
    
            );
            }
          
        }
         
        autoSaveText = (
          <span className="autoSaveTextTR">
            Your changes are automatically saved
          </span>
        );
            surveyToRender = (
              <Survey.Survey
                model={model}
                data={this.state.responses}            
                currentPage={this.state.currentPage}
                css={myCss}
                onValueChanged={this.onValueChanged}
                onComplete={this.onComplete}
                onPartialSend={this.onPartialSend}
                sendResultOnPageNext={true}
                onUploadFiles={this.onUploadFiles}
                onClearFiles={this.onClearFiles}
                onCurrentPageChanging={this.onCurrentPageChanging}    
                onAfterRenderSurvey={this.onAfterRenderSurvey}           
              />
            );
          
       
      }
    } else {
      surveyToRender = (
        <Fragment>
          <div className="spinnerModal">
            <Spinner animation="border" role="status">
           
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        
        <section className="sectionContent">
        
    <div className="page survey">{navigationToRender}{surveyToRender}{autoSaveText}</div>
        </section>
      </Fragment>
    );
  }
}
YourTaxInfo.propTypes = {};
export default YourTaxInfo;
