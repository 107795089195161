import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";
import ReactGA from 'react-ga';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import ReadOnlyQuestionnaire from "../surveyjs/ReadOnlyQuestionnaire";
import { endpoints, httpGET, httpPUT, appInsightsCode } from "../../api";
import Constants from "../utilities/Constants";
import SessionStoreManager from "../utilities/SessionStoreManager";
import Auth from "../../Auth";

class ViewTaxReturn extends Component {
  constructor(props){
    super(props);
    this.state = {
      taxYearId:"",
      taxYearProcess:""
    }
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    const appInsights = new ApplicationInsights({ config: {
      instrumentationKey: appInsightsCode
    } });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    const auth = new Auth();
    if (
      SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey) &&
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
    ) {
      this.getLatestTaxYearProcess(
        auth,
        SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId
      );
    }
    
  }

  async getLatestTaxYearProcess(auth, taxYearId) {
    //ensure that we are using the latest version of the tax year process
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters = currentUser.id + "/" + taxYearId;

    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetTaxYearProcess")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        response.body
      );
      this.setState({
        taxYearId:SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId,
        taxYearProcess:SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
      })
    }
  }
  render() {
    if(this.state.taxYearProcess === undefined || this.state.taxYearProcess === ""){
      return(<></>);
    }
    else{
      return (
        <ReadOnlyQuestionnaire
        taxYearId={this.state.taxYearId}
        taxYearProcess={this.state.taxYearProcess}
      />
      );
    }

  }
}

export default ViewTaxReturn;
