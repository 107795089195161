import React, { Component } from "react";
import SessionStoreManager from "../utilities/SessionStoreManager";
import Constants from "../utilities/Constants";
import NavControlsGenerator from "../utilities/NavControlsGenerator";
import Auth from "../../Auth";
import { endpoints, httpPOST, appInsightsCode } from "../../api";
import ReactGA from "react-ga";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

class RequestAmendments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };

    this.onMessageChange = this.onMessageChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }
  async handleSend(event) {
    const auth = new Auth();

    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    var requestToPost = {
      message: this.state.message,
      TaxYearId: currentTaxProcess.taxYearId,
      customerId: currentUser.id
    };
    let response = await httpPOST(
      endpoints.filter(
        endpoint => endpoint.name === "SendAmendmentsRequestEmail"
      )[0],
      await auth.getToken(),
      requestToPost,
      true
    );
    if (response && response.status === 200) {
      // console.log("send email status: " + response.body);
      this.props.history.push({
        pathname: "/awaitingchangenotice"
      });
    }
  }

  render() {
    return (
      <section className="sectionContent">
        <div className="page requestChanges">
          <header>
            <h2>Request Changes</h2>
          </header>
          <form className="pageContent">
            <p>Tell BDO about what you would like us to change</p>
            <section>
              <label>Message</label>
              <textarea
                maxLength="10000"
                onChange={this.onMessageChange}
                placeholder="Please explain the required amends and the relevant reasons"
              ></textarea>
            </section>
            <input
              type="button"
              className="button"
              value="Send"
              onClick={this.handleSend}
            />
          </form>

          <NavControlsGenerator currentPageNavCode={163}></NavControlsGenerator>
        </div>
      </section>
    );
  }
}

export default RequestAmendments;
