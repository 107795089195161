import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";
import ReactGA from 'react-ga';

class ViewTransmittalLetter extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }
  render() {
    return (
      <section className="sectionContent"><div className="page">
        <header>
          <h2>Review Transmittal Letter</h2>
        </header>
        <div className="pageContent"></div>
        <NavControlsGenerator currentPageNavCode={162}></NavControlsGenerator>
      </div></section>
    );
  }
}

export default ViewTransmittalLetter;
