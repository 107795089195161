import React, { Component } from "react";

import Constants from "./utilities/Constants";
import SessionStoreManager from "./utilities/SessionStoreManager";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FAQs from "./misc/FAQs";
import { endpoints, httpPOST, httpGET } from "../api";
import Auth from "../Auth";


class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      showFAQs: false
    };
  }
  async updateNavNode(NavNode) {
    const auth = new Auth();

    let taxYearId = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    ).taxYearId;
    await this.getTaxYearProcess(auth, taxYearId);

    let taxYearProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    taxYearProcess.currentNavNodeCode = NavNode;
    await this.updateDB(auth, taxYearProcess);

    window.location.reload();
  }

  async getTaxYearProcess(auth, taxYearId) {
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters = currentUser.id + "/" + taxYearId;

    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetTaxYearProcess")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        response.body
      );
    }
  }

  async updateDB(auth, taxYearObj) {
    let response = await httpPOST(
      endpoints.filter(
        endpoint => endpoint.name === "GetOrCreateTaxYearProcess"
      )[0],
      await auth.getToken(),
      taxYearObj,
      true
    );
    if (response && response.status === 200) {
      // Update cache once DB was successfully updated
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        taxYearObj
      );
    }
  }
  handleNextNavigationFAQ = (c) => {
  
    this.setState({      
      showFAQs: true
    });
  };
  handleNextNavigationFAQCancel = (c) => {
    this.setState({
      showFAQs: false
    });
  };
  
  render() {
    var footerClass = "globalFooter"
    var contactUsLink = ""
    var fAQsModal = ""
    if (window.location.pathname === "/") {
      contactUsLink = (
        <a 
  href="https://www.bdo.co.uk/en-gb/services/tax/private-client-services/contact-us"
  title="contactUs"
  target="_blank"
  rel="noopener noreferrer"
>Contact us
</a>
      );

    }
    else
    {
      contactUsLink = (
        <a onClick={() => this.updateNavNode(123)}>Contact us</a>
      );
    }
    if(window.location.pathname.startsWith("/medicalquestionnaire")){
      footerClass = "globalFooter medicalFooter"
    }

    if (this.state.showFAQs) {
      fAQsModal =  (
        <Modal
        className="faqsModal"
          size="lg"
          centered
          show={true}
          onHide={this.handleNextNavigationFAQCancel.bind(this)}
          aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
    <Modal.Title>Frequently asked questions</Modal.Title>
  </Modal.Header>
          <Modal.Body>
          <FAQs />
          </Modal.Body>
          <Modal.Footer>            
            {/* <Button
              className="button"
              onClick={this.handleNextNavigationFAQCancel.bind(this)}
            >
              Close
            </Button> */}
          </Modal.Footer>
        </Modal>
      );
    }
    
    return (
      <footer className={footerClass}>
        {fAQsModal}
      <div className="desktopWrapper">
      <section className="bdoInformation">
      <div className="legalPrivacy"><span className="copyright">Copyright &copy; <span className="dynamicYear">{(new Date().getFullYear())}</span> BDO LLP</span>
    <a onClick={this.handleNextNavigationFAQ.bind(this)}>FAQs</a>
      
      <a href="https://www.bdo.co.uk/en-gb/privacy-statement" target="_blank" rel="noopener noreferrer">Legal &amp; Privacy</a>
      {contactUsLink}
      </div>
      </section>
      <section className="supportInformation">
        <span>If you are encountering any technical issues please call <span id="supportNumber"><b>0208 057 1200</b></span>.</span>
        <br/>
        <span>This service is available Monday to Friday 9am - 5pm</span>
        <br/>
        <span>Outside of those hours please email: <a href="mailto:optsupport@bdo.co.uk" id="supportEmail">optsupport@bdo.co.uk</a></span>
        <br/>
        <br/>
        <span>If you have any questions regarding tax matters, please reach out to your usual contact within the BDO Tax team</span>
      </section>
      <section className="socialMedia">
        <div className="socialItem"><a href="https://www.facebook.com/BDOLLP/" title="Facebook" target="_blank" rel="noopener noreferrer"><span className="socialIcon facebook"></span></a></div>
        <div className="socialItem"><a href="https://twitter.com/bdoaccountant" title="Twitter" target="_blank" rel="noopener noreferrer"><span className="socialIcon twitter"></span></a></div>
        <div className="socialItem"><a href="https://www.linkedin.com/company/bdo-llp" title="Linkedin" target="_blank" rel="noopener noreferrer"><span className="socialIcon linkedin"></span></a></div>
        <div className="socialItem"><a href="https://www.youtube.com/user/bdollp" title="Youtube" target="_blank" rel="noopener noreferrer"><span className="socialIcon youtube"></span></a></div>
        <div className="socialItem"><a href="https://www.instagram.com/bdo_uk" title="Instagram" target="_blank" rel="noopener noreferrer"><span className="socialIcon instagram"></span></a></div>
      </section>
      </div>
    </footer>
    )
}
}

export default Footer;
