class Constants {
  // Cache keys
  static navRulesKey = "navigationRules";
  static navNodesKey = "navigationNodes";
  static currentUserB2CKey = "currentUserB2C";
  static taxYearProcessKey = "taxYearProcess";
  static currentUserCosmosDBKey = "currentUserCosmosDB";
  static settingsCosmosDBKey = "settingCosmosDB";
  static blobContainerUri = "blobContainerUri";
  static pageSections = [
                          {pageName: "P2 Personal Information", section: "P2 Personal Information"},
                          {pageName: "P3 Residence", section: "P3 Residence"},
                          {pageName: "P4 Domicile", section: "P3 Residence"},
                          {pageName: "P31 Non-UK Investment Income (Continued)", section: "P3 Residence"},                          
                          {pageName: "P9 Self Employment", section: "P9 Self Employment"},
                          {pageName: "P10 Self Employment 1", section: "P9 Self Employment"},
                          {pageName: "P11 Self Employment 2", section: "P9 Self Employment"},
                          {pageName: "P12 Self Employment 3", section: "P9 Self Employment"},
                          {pageName: "P13 Partnership Income", section: "P13 Partnership Income"},   
                          {pageName: "P14 Partnership Income Details 1", section: "P13 Partnership Income"},
                          {pageName: "P15 Partnership Income Details 2", section: "P13 Partnership Income"},
                          {pageName: "P16 Partnership Income Details 3", section: "P13 Partnership Income"},                      
                          {pageName: "P17 UK investment income", section: "P17 UK investment income"},
                          {pageName: "P19 UK Investment Income (Continued)", section: "P17 UK investment income"},
                          {pageName: "P24 Non-UK Investment Income", section: "P17 UK investment income"},
                          {pageName: "P26 Non-UK Investment Income (Continued)", section: "P17 UK investment income"},
                          {pageName: "P18 UK Rental Income", section: "P18 UK Rental Income"},
                          {pageName: "P20 Rental Income From UK Property 1", section: "P18 UK Rental Income"},
                          {pageName: "P21 Rental Income From UK Property 2", section: "P18 UK Rental Income"},
                          {pageName: "P22 Rental Income From UK Property 3", section: "P18 UK Rental Income"},
                          {pageName: "P23 Rental Income From UK Property 4", section: "P18 UK Rental Income"},
                          {pageName: "P25 Non-UK Rental Income", section: "P18 UK Rental Income"},
                          {pageName: "P27 Rental from non-UK property 1", section: "P18 UK Rental Income"},
                          {pageName: "P28 Rental from non-UK property 2", section: "P18 UK Rental Income"},
                          {pageName: "P29 Rental from non-UK property 3", section: "P18 UK Rental Income"},
                          {pageName: "P29 Rental from non-UK property 4", section: "P18 UK Rental Income"},
                          {pageName: "P32 Other Income", section: "P32 Other Income"},
                          {pageName: "P34 Other Income (Continue)", section: "P32 Other Income"},
                          {pageName: "P35 Personal pension contributions", section: "P35 Personal pension contributions"},
                          {pageName: "P36 Outgoings and reliefs", section: "P35 Personal pension contributions"},
                          {pageName: "P38 Additional Information", section: "P35 Personal pension contributions"},
                          {pageName: "P39 Declaration", section: "P39 Declaration"},
                          {pageName: "P40 Your responsibilities", section: "P39 Declaration"}
                        ];
}

export default Constants;
