import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";
import ReactGA from 'react-ga';

class Payment extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }
  render() {
    return (
      <section className="sectionContent"><div className="page">
        <header><h2>Payment</h2></header>  
    <div className="pageContent"></div> 
        
        <NavControlsGenerator currentPageNavCode={210}></NavControlsGenerator>
      </div></section>
    );
  }
}

export default Payment;
