import React, { Component } from "react";
import Auth from "../../Auth.js";
import Constants from "../utilities/Constants";
import SessionStoreManager from "../utilities/SessionStoreManager";
import {
  baseURL,
  endpoints,
  httpGET,
  httpPOST,
  httpDELETE,
  httpPUT,
  appInsightsCode
} from "../../api";
import ErrorBoundary from "../utilities/ErroroBoundary";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactGA from "react-ga";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

class TellUsMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tiles: [],
      responses: [],
      previousResponses: [],
      isLoading: true,
      isCutomerTypeNew: true,
      isClicking: false,
      alertVisable: false,
      tileCancelConfirmation: false,
      filterQuestionId: "",
      filterQuestionTileId: "",
      filterQuestionTileResponseId: "",
      filterQuestionResponseId: "",
      filterQuestionResponses: [],
      tilesNotFound: "",
      customersQuestionnaireVersion: "",
      movingtoQuestionnaire: false,
      taxYear: "",
      taxYearTo: "",
      previousTaxYearName: ""
    };
    this.baseURL = baseURL;
    this.handleTitleClick = this.handleTitleClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.prepareForNavigationFunc = this.prepareForNavigationFunc.bind(this);
    this.handleMoveToQuestionnaire = this.handleMoveToQuestionnaire.bind(this);
    this.handleQuestionnaireMoveConfirmationCancel = this.handleQuestionnaireMoveConfirmationCancel.bind(
      this
    );
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    const auth = new Auth();
    if (
      SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey) &&
      SessionStoreManager.getValueByKey(Constants.navRulesKey) &&
      SessionStoreManager.getValueByKey(Constants.navNodesKey) &&
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
    ) {
      //Set the customer type
      let customerIdProcessType = SessionStoreManager.getValueByKey(
        Constants.currentUserCosmosDBKey
      );
      if (
        customerIdProcessType.customerTypeId ===
        "86b09f9b-a58d-460f-b325-b52dbc2bd52c"
      ) {
        this.setState({
          isCutomerTypeNew: true
        });
      } else {
        this.setState({
          isCutomerTypeNew: false
        });
      }
      this.getTaxYearName(auth);
      this.getPreviousTaxYearName(auth);
      this.LoadTiles(auth);
    }
  }
  async getTaxYearName(auth) {
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );

    var parametersAttachment = currentTaxProcess.taxYearId;
    let response = await httpGET(
      endpoints.filter(endpoint => endpoint.name === "GetTaxYear")[0],
      parametersAttachment,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      this.setState({
        taxYear: response.body[0].fromYear + "/" + response.body[0].toYear.toString().slice(-2),
        taxYearTo: response.body[0].toYear.toString()
      });
    }
  }
  async getPreviousTaxYearName(auth) {
    let previousTaxYearId = SessionStoreManager.getValueByKey(
      Constants.settingsCosmosDBKey
    ).previousTaxYearId;

    var parametersAttachment = previousTaxYearId;
    let response = await httpGET(
      endpoints.filter(endpoint => endpoint.name === "GetTaxYear")[0],
      parametersAttachment,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      this.setState({
        previousTaxYearName: response.body[0].fromYear + "/" + response.body[0].toYear.toString().slice(-2)        
      });
    }
  }
  async LoadTiles(auth) {
    let taxYearProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );

    //Check to see if a customer has a questionnaire version listed against their tax return process
    if (!taxYearProcess.questionnaireVersion) {
      // If not, set their questionnaire version in the tax retun process to be the version listed in the settings table
      taxYearProcess.questionnaireVersion = SessionStoreManager.getValueByKey(
        Constants.settingsCosmosDBKey
      ).currentQuestionnaireVersion;

      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        taxYearProcess
      );
      await this.UpdateTaxYearProccess(auth, taxYearProcess);
    }

    let taxyearid = taxYearProcess.taxYearId;

    var parametersTile = taxyearid + "/" + taxYearProcess.questionnaireVersion;

    let response = await httpGET(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetFilterQuestionTileByTaxYearIdAndQuestionnaireVersion"
      )[0],
      parametersTile,
      await auth.getToken(),
      true
    );
    if (response && response.status === 200) {
      var responsedata = response.body;
      //console.log(responsedata);
      this.setState({
        tiles: responsedata,
        tilesNotFound: ""
      });
      this.LoadResponses(auth);
      this.LoadPreviousResponses(auth);
    } else {
      this.setState({
        tilesNotFound: "No options found"
      });
    }
  }

  async LoadResponses(auth) {
    let taxYearProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    let customerIdProcess = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );

    let taxyearid = taxYearProcess.taxYearId;
    let customerId = customerIdProcess.id;
    var parameters = taxyearid + "/" + customerId;
    //console.log("parameters: " + parameters);
    let response = await httpGET(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetFilterQuestionTileResponseByTaxYearIdAndCustomerId"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      var responsedata = response.body;
      //console.log(responsedata);
      this.setState({
        responses: responsedata,
        isLoading: false,
        isClicking: false
      });
      //if the responses are competed the user should be moved on to the questionnaire page automoatically
      var isCompleted = responsedata.find(r => r.isComplete);
      if (isCompleted) {
        if (isCompleted.isComplete === true) {
          this.props.history.push({
            pathname: "/yourtaxinfo"
          });
        }
      }
    }
    if (response && response.status === 204) {
      //No responses yet. Record that the questionnaire is started
      //Questionnaire is started
      if (!taxYearProcess.questionnaireStarted) {
        var today = new Date();
        taxYearProcess.questionnaireStarted = today.toISOString();
        SessionStoreManager.setValueForKey(
          Constants.taxYearProcessKey,
          taxYearProcess
        );
        await this.UpdateTaxYearProccess(auth, taxYearProcess);
      }

      // console.log(responsedata);
      this.setState({
        responses: [],
        isLoading: false,
        isClicking: false
      });
    }
  }
  async LoadPreviousResponses(auth) {
    let previousTaxYearId = SessionStoreManager.getValueByKey(
      Constants.settingsCosmosDBKey
    ).previousTaxYearId;
    let customerIdProcess = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );

    let taxyearid = previousTaxYearId;
    let customerId = customerIdProcess.id;
    var parameters = taxyearid + "/" + customerId;
    let response = await httpGET(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetFilterQuestionTileResponseByTaxYearIdAndCustomerId"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      var responsedata = response.body;
      //console.log(responsedata);
      this.setState({
        previousResponses: responsedata,
        isLoading: false,
        isClicking: false
      });
      
    }
    
  }
  async UpdateTaxYearProccess(auth, currentTaxProcess) {
    let response = await httpPUT(
      endpoints.filter(
        endpoint => endpoint.name === "GetOrCreateTaxYearProcess"
      )[0],
      currentTaxProcess.id,
      await auth.getToken(),
      currentTaxProcess
    );
    if (response && response.status === 200) {
      /*console.log(
      "updating taxyear porcess: " + JSON.stringify(currentTaxProcess)
    );
    console.log("getOrCreateTaxYearProcess response: " + response);    */
    }
  }
  async LoadAndDeleteFilterQuestionResponse(filterquestionid, auth) {
    var parameters =
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId +
      "/" +
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
        .customerId +
      "/" +
      filterquestionid;

    let response = await httpGET(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetFilterQuestionResponseByTaxYearIdCustomerIdAndFilterQuestionId"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      /* console.log(
        "GetFilterQuestionResponseByTaxYearIdCustomerIdAndFilterQuestionId response: " +
          JSON.stringify(response)
      );*/

      var responsedata = JSON.parse(response.body.responsesJson);
      this.setState({
        filterQuestionResponses: responsedata,
        filterQuestionResponseId: response.body.id
      });
      this.DeleteFilterQuestionResponse(
        this.state.filterQuestionResponseId,
        auth
      );
    }
  }
  async DeleteResponse(id, auth) {
    let customerIdProcess = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );

    let customerId = customerIdProcess.id;
    var parameters = id + "/" + customerId;
    //console.log("parameters: " + parameters);
    let response = await httpDELETE(
      endpoints.filter(
        endpoint => endpoint.name === "DeleteFilterQuestionTileResponse"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      //var responsedata = response.body;
      // console.log(responsedata);
      this.setState({
        filterQuestionTileId: "",
        filterQuestionId: ""
      });
      this.LoadResponses(auth);
      this.LoadPreviousResponses(auth);
    }
  }
  async DeleteFilterQuestionResponse(id, auth) {
    let customerIdProcess = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );

    let customerId = customerIdProcess.id;
    var parameters = id + "/" + customerId;
    // console.log("parameters: " + parameters);
    let response = await httpDELETE(
      endpoints.filter(
        endpoint => endpoint.name === "DeleteFilterQuestionResponse"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      //var responsedata = response.body;
      // console.log(responsedata);
      this.setState({
        filterQuestionResponses: [],
        filterQuestionResponseId: ""
      });
      this.DeleteResponse(this.state.filterQuestionTileResponseId, auth);
    }
  }
  prepareForNavigationFunc() {
    if (this.state.responses.length < 1 || this.state.responses === undefined) {
      this.setState({
        movingtoQuestionnaire: false
      });
      this.setState({
        alertVisible: true
      });
    } else {
      //set all responses to be complete
      this.setToComplete();
    }
  }
  handleQuestionnaireMoveConfirmationCancel() {
    this.setState({
      movingtoQuestionnaire: false
    });
  }
  async setToComplete() {
    const auth = new Auth();
    let taxYearProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    let customerIdProcess = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );

    let taxyearid = taxYearProcess.taxYearId;
    let customerId = customerIdProcess.id;
    //{taxYearId}/{customerId}/{questionnaireVersion}/{setToComplete}
    var version = taxYearProcess.questionnaireVersion;
    var parameters = taxyearid + "/" + customerId + "/" + version + "/" + true;
    //console.log("parameters: " + parameters);
    let response = await httpGET(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetFilterQuestionTileResponseByTaxYearIdAndCustomerId"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      //Move to questionnaire
      taxYearProcess.CurrentNavNodeCode = 200;
      this.UpdateTaxYearProccess(auth, taxYearProcess);
      this.props.history.push({
        pathname: "/yourtaxinfo"
      });
    }
  }
  async upsertTileResponse(tileResponseId, filterQuestionTileId, selected) {
    let taxYearProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    let customerIdProcess = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );

    let taxyearid = taxYearProcess.taxYearId;
    let customerId = customerIdProcess.id;

    var filterQuestionTileResponse = {};
    if (tileResponseId) {
      filterQuestionTileResponse = {
        id: tileResponseId,
        customerId: customerId,
        taxYearId: taxyearid,
        filterQuestionTileId: filterQuestionTileId,
        selected: selected
      };
    } else {
      filterQuestionTileResponse = {
        customerId: customerId,
        taxYearId: taxyearid,
        filterQuestionTileId: filterQuestionTileId,
        selected: selected
      };
    }

    const auth = new Auth();

    let payload = filterQuestionTileResponse;
    let response = await httpPOST(
      endpoints.filter(
        endpoint => endpoint.name === "UpsertFilterQuestionTileResponse"
      )[0],
      await auth.getToken(),
      payload,
      true
    );
    if (response && response.status === 200) {
      //console.log("UpsertResponse response: " + response.statusText);
      // get updated responses

      this.LoadResponses(auth);
      this.LoadPreviousResponses(auth);
    }
  }

  getTileStyle(questionId, linkQuestionId) {
    var selectedResult = this.state.responses.filter(
      e => e.filterQuestionTileId === questionId
    );
 

    if (selectedResult.length > 0) {
      return "tile selected";
    } else {
      if (linkQuestionId !== "")
      {
        var previouslySelectedResult = this.state.previousResponses.filter(
          e => e.filterQuestionTileId === linkQuestionId
        );
        if (previouslySelectedResult.length > 0) {
          return "tile previouslyselected";
        }
        else
        {
          return "tile unselected";
        }

      }
      else
      {
        
      }
      
    }
  }
  getTilePreviouslySelectedLabelVisible(questionId, linkQuestionId) {
  

      if (linkQuestionId !== "")
      {
        var previouslySelectedResult = this.state.previousResponses.filter(
          e => e.filterQuestionTileId === linkQuestionId
        );
        if (previouslySelectedResult.length > 0) {
          return false;
        }
        else
        {
          return true;
        }

      }
      
      
    }
  
  handleMoveToQuestionnaire(e) {
    this.setState({
      movingtoQuestionnaire: true
    });
  }
  handleRemoveClick(filterQuestionId, filterQuestionTileId, e) {
    const auth = new Auth();
    try {
      this.setState({
        isClicking: true
      });
      var selectedResult = this.state.responses.filter(
        e => e.filterQuestionTileId === filterQuestionTileId
      );

      //need to handle user confirmation if user is new and there are filter questions
      if (this.state.isCutomerTypeNew) {
        if (filterQuestionId === null) {
          this.DeleteResponse(selectedResult[0].id, auth);
        } else {
          this.setState({
            tileCancelConfirmation: true,
            filterQuestionTileId: filterQuestionTileId,
            filterQuestionId: filterQuestionId,
            filterQuestionTileResponseId: selectedResult[0].id
          });
        }
      } else {
        this.DeleteResponse(selectedResult[0].id, auth);
      }
    } catch (error) {
      this.setState({
        isClicking: false
      });
    }
  }
  isHidden(filterQuestionId, filterQuestionTileId) {
    var selectedResult = this.state.responses.filter(
      e => e.filterQuestionTileId === filterQuestionTileId
    );
    if (selectedResult.length > 0) {
      return false;
    } else {
      return true;
    }
  }
  handleTitleClick(filterQuestionId, filterQuestionTileId, e) {
    const auth = new Auth();
    try {
      this.setState({
        isClicking: true
      });
      var selectedResult = this.state.responses.filter(
        e => e.filterQuestionTileId === filterQuestionTileId
      );
      if (selectedResult.length > 0) {
        //need to handle user confirmation if user is new and there are filter questions
        if (this.state.isCutomerTypeNew) {
          if (filterQuestionId === null) {
            this.DeleteResponse(selectedResult[0].id, auth);
          } else {
            this.props.history.push({
              pathname: "/filterquestion",
              state: {
                filterQuesitonTileId: filterQuestionTileId,
                filterQuesitonId: filterQuestionId
              }
            });
          }
        } else {
          this.DeleteResponse(selectedResult[0].id, auth);
        }
      } else {
        if (this.state.isCutomerTypeNew) {
          if (filterQuestionId === null) {
            //No filter questions so just toggle
            this.upsertTileResponse(null, filterQuestionTileId, true);
          } else {
            //filter questions required
            this.props.history.push({
              pathname: "/filterquestion",
              state: {
                filterQuesitonTileId: filterQuestionTileId,
                filterQuesitonId: filterQuestionId
              }
            });
          }
        } else {
          this.upsertTileResponse(null, filterQuestionTileId, true);
        }
      }
    } catch (error) {
      this.setState({
        isClicking: false
      });
    }
  }
  handleAlertDismiss() {
    this.setState({ alertVisible: false });
  }
  handleAlertConfirmationCancel() {
    this.setState({ tileCancelConfirmation: false, isClicking: false });
  }
  handleAlertConfirmationConfirm() {
    const auth = new Auth();
    this.setState({
      filterQuestionResponses: [],
      filterQuestionResponseId: ""
    });
    //save the selection

    this.LoadAndDeleteFilterQuestionResponse(this.state.filterQuestionId, auth);

    this.setState({ tileCancelConfirmation: false, isClicking: false });
  }

  render() {
    let renderTiles = "";
    var renderAlert = "";
    var conditionalText = "";
    var renderMovingToQuestionnaire = "";
    if (this.state.movingtoQuestionnaire) {
      renderMovingToQuestionnaire = (
        <Modal
          size="sm"
          centered
          show={true}
          onHide={this.handleQuestionnaireMoveConfirmationCancel.bind(this)}
          aria-labelledby="example-custom-modal-styling-title"
        >
          {/* <Modal.Title>Please Confirm</Modal.Title> */}
          <Modal.Body>
            Are you sure you have selected all relevant options?
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="button"
              onClick={this.handleQuestionnaireMoveConfirmationCancel.bind(
                this
              )}
            >
              No
            </Button>
            <Button
              className="button"
              onClick={this.prepareForNavigationFunc.bind(this)}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    var renderConfirmation = "";
    if (this.state.tiles.length > 0) {
      if (this.state.previousResponses.length > 0){
        conditionalText = "Categories labelled with " + this.state.previousTaxYearName + " were relevant to your tax return last year";
      }
      var tilesToRender = this.state.tiles;
      //order tiles
      if (this.state.isClicking) {
        renderTiles = tilesToRender.map((item, key) => (
          <figure key={item.id + "1"} className={this.getTileStyle(item.id, item.linkedPreviousFilterQuestionId)} name={item.title}>
            <button
              key={item.id}
              className="close"
              hidden={this.isHidden(item.filterQuestionId, item.id)}
              type="button"
            >
              &times;
            </button>
            <figcaption className="activeArea" key={item.id + "2"}>
              <div className="prevYear" hidden={this.getTilePreviouslySelectedLabelVisible(item.id, item.linkedPreviousFilterQuestionId)}>{this.state.previousTaxYearName}</div>
              <div className="tileIcon"></div>
              <div className="tileTitle">{item.title}</div>
            </figcaption>
          </figure>
        ));
      } else {
        renderTiles = tilesToRender.map((item, key) => (
          <figure key={item.id + "1"} className={this.getTileStyle(item.id, item.linkedPreviousFilterQuestionId)} name={item.title}>
            <button
              key={item.id}
              className="close"
              hidden={this.isHidden(item.filterQuestionId, item.id)}
              type="button"
              onClick={e =>
                this.handleRemoveClick(item.filterQuestionId, item.id, e)
              }
            >
              &times;
            </button>
            <figcaption
              className="activeArea"
              key={item.id + "2"}
              onClick={e =>
                this.handleTitleClick(item.filterQuestionId, item.id, e)
              }
            >
              <div className="prevYear" hidden={this.getTilePreviouslySelectedLabelVisible(item.id, item.linkedPreviousFilterQuestionId)}>{this.state.previousTaxYearName}</div>
              <div className="tileIcon"></div>
              <div className="tileTitle">{item.title}</div>
            </figcaption>
          </figure>
        ));
      }

      if (this.state.alertVisible) {
        renderAlert = (
          <Modal
            /* className="alert" */
            size="sm"
            centered
            show={true}
            onHide={this.handleAlertDismiss.bind(this)}
            aria-labelledby="alertDialog-small"
          >
            <Modal.Body className="alert">
              Please select a source of income
            </Modal.Body>
          </Modal>
        );
      } else {
        renderAlert = "";
      }
      if (this.state.tileCancelConfirmation && this.state.isCutomerTypeNew) {
        renderConfirmation = (
          <div>
            <Modal
              size="sm"
              centered
              show={true}
              onHide={this.handleAlertConfirmationCancel.bind(this)}
              aria-labelledby="example-custom-modal-styling-title"
            >
              {/* <Modal.Title>Please Confirm</Modal.Title> */}
              <Modal.Body>
                Are you sure you want to remove this?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="button cancel"
                  onClick={this.handleAlertConfirmationCancel.bind(this)}
                >
                  No
                </Button>
                <Button
                  className="button"
                  onClick={this.handleAlertConfirmationConfirm.bind(this)}
                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      } else {
        renderConfirmation = "";
      }
    }
    return (
      <section className="sectionContent">
        <div className="page filterQuestions">
          <header>
            <h2>Tell us about your tax status for <span>{this.state.taxYear}</span></h2>
            <h3>Please tell us which of the following categories were relevant to you for the year ended 5 April <span>{this.state.taxYearTo}</span></h3>
            <h3>{conditionalText}</h3>
          </header>
          {this.state.tilesNotFound}
          {renderAlert}
          {renderConfirmation}
          <div className="pageContent">
            <div className="tileGrid">
              <ErrorBoundary>
                {renderTiles}
              </ErrorBoundary>
            </div>
            <footer className="page-footer">
              {" "}
              <Button
                className="button"
                onClick={e => this.handleMoveToQuestionnaire(e)}
              >
                Continue
            </Button>
            </footer>
          </div>
          
          {renderMovingToQuestionnaire}
        </div>
      </section>
    );
  }
}

export default TellUsMore;
