import React, { Component } from "react";
import Auth from "../../Auth";
import { endpoints, httpGET, appInsightsCode } from "../../api";
import SessionStoreManager from "../utilities/SessionStoreManager";
import Constants from "../utilities/Constants";
import TaxYearMessages from "./TaxYearMessages";
import ReactGA from "react-ga";
import ContactUs from "../misc/ContactUs";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Modal from "react-bootstrap/Modal";


import Accordion from "react-bootstrap/Accordion";

export default class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false,
      customerId: undefined,
      showContactUs: false
    };
    this.handleShowMessage = this.handleShowMessage.bind(this);
    this.handleCloseMessage = this.handleCloseMessage.bind(this);
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    const auth = new Auth();
    if (
      SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey) &&
      SessionStoreManager.getValueByKey(Constants.navRulesKey) &&
      SessionStoreManager.getValueByKey(Constants.navNodesKey) &&
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
    ) {
      this.getLatestTaxYearProcess(
        auth,
        SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId
      );

      this.getAllTaxReturnProcesses(auth);
    }
  }
  async getLatestTaxYearProcess(auth, taxYearId) {
    //ensure that we are using the latest version of the tax year process
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters = currentUser.id + "/" + taxYearId;

    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetTaxYearProcess")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      this.setState({
        currentTaxYearProcess: response.body,
        customerId: currentUser.id,
      });
    }
  }
updateNavNode(){
this.props.history.push({
    pathname: "/contactus"
  });

}

  
  
  async getAllTaxReturnProcesses() {
    const auth = new Auth();
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var parameters =
      currentUser.id +
      "/" +
      false +
      "/" +
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId;

    let response = await httpGET(
      endpoints.filter((endpoint) => endpoint.name === "GetTaxYearProcess")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      this.setState({
        allTaxReturnProcesses: response.body,
      });
    }
  }
  handleShowMessage() {
    this.setState({
      showContactUs: true,
    });
  }

  handleCloseMessage() {
    this.setState({
      showContactUs: false,
    });
    window.location.reload();
  }

  render() {
    var historical = "";
    var currentTaxYearMessages = "";
    var viewMessageContent = "";
    if (this.state.allTaxReturnProcesses) {
      historical = this.state.allTaxReturnProcesses.map((value, index) => {
        return (
          <TaxYearMessages
            eventKey={value.taxYearId}
            customerId={this.state.customerId}
            taxYearId={value.taxYearId}
            key={value.taxYearId}
            historical="true"
          />
        );
      });
    }
    if (this.state.currentTaxYearProcess) {
      currentTaxYearMessages = (
        <TaxYearMessages
          eventKey="0"
          customerId={this.state.customerId}
          taxYearId={this.state.currentTaxYearProcess.taxYearId}
          key={this.state.currentTaxYearProcess.taxYearId}
          index="0"
          historical="false"
        />
      );
    }
    if (this.state.showContactUs === true) {
    viewMessageContent = (
      <div className="viewMessageContent">
        <Modal
          key={this.props.htmlid + this.props.type + "modal"}
          size="xl"
          centered
          show={true}
          onHide={this.handleCloseMessage.bind(this)}
          aria-labelledby="messageModal"
          className="fileViewer"
        >
          <Modal.Header closeButton>
          Contact us
          </Modal.Header>
          <Modal.Body>
           <ContactUs />
          </Modal.Body>
        </Modal>
      </div>
    );
    }

    return (
      <section className="sectionContent">
        <div className="page messages">
        <header className="commsHead">
          <h2>Your messages</h2>          
         <button onClick={() => this.handleShowMessage()}>Contact us</button>
        </header>
          <div className="pageContent">
            <Accordion defaultActiveKey="0">
              {currentTaxYearMessages}
              {historical}
            </Accordion>
            {viewMessageContent}
          </div>
        </div>
      </section>
    );
  }
}
