import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";
import {
  webURL,
  pwdAuthorityB2C,
  clientIdB2C,
  resetPasswordPolicyB2C
} from "../../api";

import ReactGA from 'react-ga';
import Auth from "../../Auth";

class PasswordReset extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }
  handlePasswordReset = c => {
    window.location.href =
      // "https://bdoukb2cdevief.b2clogin.com/bdoukb2cdevief.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_BDO_PasswordReset&client_id=055d82be-6483-48bf-a2f6-616852c94394&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fsignupredirect&scope=openid&response_type=id_token&prompt=login&state=215e2300-38b0-4921-bf7f-2212d4a8ad66";
      //"https://bdoukb2cdevief.b2clogin.com/bdoukb2cdevief.onmicrosoft.com/b2c_1a_bdo_passwordreset/oauth2/v2.0/authorize?client_id=055d82be-6483-48bf-a2f6-616852c94394&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fsignupredirect&response_type=id_token&scope=openid%20profile&state=215e2300-38b0-4921-bf7f-2212d4a8ad66";

      pwdAuthorityB2C +
      "/" +
      resetPasswordPolicyB2C +
      "/oauth2/v2.0/authorize?client_id=" +
      clientIdB2C +
      "&redirect_uri=" +
      webURL +
      "signupredirect" +
      "&response_type=id_token&scope=openid%20profile&state=215e2300-38b0-4921-bf7f-2212d4a8ad66";
  };

  handleSignout = c => {
    const auth = new Auth();
    auth.logout();
  };

  render() {
    return (
      <section className="sectionContent">
        <div className="page">
          <header>
            <h2>Password Reset</h2>
          </header>
          <div className="pageContent">
            <button
              className="button getStarted"
              onClick={this.handlePasswordReset.bind(this)}
            >
              Reset Password
            </button>
            <button
              className="button getStarted"
              onClick={this.handleSignout.bind(this)}
            >
              Logout
            </button>
          </div>
          <NavControlsGenerator currentPageNavCode={150}></NavControlsGenerator>
        </div>
      </section>
    );
  }
}

export default PasswordReset;
