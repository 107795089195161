import React, { Component, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Auth from "../../Auth";
import { endpoints, httpGET } from "../../api";

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false,
      files: undefined,
      articleExpansionClass: "collapsed"
    };
    this.handleShowMessage = this.handleShowMessage.bind(this);
    this.handleCloseMessage = this.handleCloseMessage.bind(this);
    this.handleExpansion = this.handleExpansion.bind(this);
  }
  componentDidMount() {
    const auth = new Auth();
    if (this.props.files) {
      this.getFiles(this.props.files, auth);
    }
  }

  async getFiles(item, auth) {
    var filesFull = item;
    var i;
    for (i = 0; i < filesFull.length; i++) {
      //get the link
      //{containerName}/{taxYear}/{blobFileFolder}/{blobName}
      var parametersAttachment =
        this.props.customerId +
        "/" +
        this.props.taxYearId +
        "/" +
        filesFull[i].path +
        "/" +
        filesFull[i].name;
      let response = await httpGET(
        endpoints.filter(
          (endpoint) => endpoint.name === "GetAttachmentURLFromBlobContainer"
        )[0],
        parametersAttachment,
        await auth.getToken(),
        false
      );
      if (response && response.status === 200) {
        var fullpath = "fullpath";
        var newValue = response.body;
        filesFull[i][fullpath] = newValue;
      }
    }
    this.setState({
      files: filesFull,
    });
  }

  handleShowMessage() {
    this.setState({
      showMessage: true,
    });
  }

  handleCloseMessage() {
    this.setState({
      showMessage: false,
    });
  }

  handleExpansion() {
    if (this.state.articleExpansionClass === "collapsed")
    {
      this.setState({
        articleExpansionClass: "expanded",
      });
    }
    else
    {
      this.setState({
        articleExpansionClass: "collapsed",
      });
    } 
  }

  render() {
    var message = "";
    var viewMessageContent = "";
    var linksToFiles = "";
    var date = new Date(this.props.date);
    let formatted_date = date.toLocaleDateString("en-GB");
    let formatted_time = date.toLocaleTimeString("en-GB");
    //Show link to attachments
    if (this.props.files) {
      linksToFiles = (
        <button id={this.props.htmlid + this.props.type}
          onClick={this.handleShowMessage}
          className="attachment"
        ></button>
      );
    }
    //Show a particular style of message depending on the type of message
    if (this.props.from === "System") {
      message = (
        <li className="gridRow systemMessage">
          <span className="avatar"></span>
          <div className="message">
            <div className="description">
              <h4>{this.props.title}</h4>
              <span className="date">{formatted_date}</span>
            </div>
            {linksToFiles}
            <article>{this.props.description}</article>
          </div>
        </li>
      );
    } else {
      if (this.props.from === "BDO") {
        message = (
          <li className="gridRow bdoMessage">
            <span className="avatar"></span>
            <div className="message">
              <header>
                <div className="description">
                  <h4>{this.props.title}</h4>
                  <span className="date">{formatted_date}
                      <span className="time"> {formatted_time}</span>
                    </span>
                </div>
                {linksToFiles}
              </header>
              <article className={this.state.articleExpansionClass}>
                {this.props.description}
                <button id={this.props.htmlid} className="conversation" onClick={this.handleExpansion}>
                  <span className="expDot"></span>
                  <span className="expDot"></span>
                  <span className="expDot"></span>
                </button>
              </article>
            </div>
          </li>
        );
      } else {
        if (this.props.from === "Client") {
          message = (
            <li className="gridRow clientMessage">
              <span className="avatar"></span>
              <div className="message">
                <header>
                  <div className="description">
                    <h4>{this.props.title}</h4>
                    <span className="date">{formatted_date}
                      <span className="time"> {formatted_time}</span>
                    </span>
                  </div>
                  {linksToFiles}
                </header>
                <article className={this.state.articleExpansionClass}>
                  {this.props.description}
                  <button id={this.props.htmlid + this.props.type} className="conversation" onClick={this.handleExpansion}>
                    <span className="expDot"></span>
                    <span className="expDot"></span>
                    <span className="expDot"></span>
                  </button>
                </article>
              </div>
            </li>
          );
        } else {
          message = (
            <li className="gridRow bdoMessage">
              <span className="avatar"></span>
              <div className="message">
                <header>
                  <div className="description">
                    <h4>{this.props.title}</h4>
                    <span className="date">{formatted_date}
                      <span className="time"> {formatted_time}</span>
                    </span>
                  </div>
                  {linksToFiles}
                </header>
                <article className={this.state.articleExpansionClass}>{this.props.description}
                <button id={this.props.htmlid + this.props.type} className="conversation" onClick={this.handleExpansion}>
                    <span className="expDot"></span>
                    <span className="expDot"></span>
                    <span className="expDot"></span>
                  </button></article>
              </div>
            </li>
          );
        }
      }
    }
    //Show modal if you click into  the message or folder image
    if (this.state.showMessage === true) {
      if (this.props.files) {
        if (this.state.files)
          linksToFiles = this.state.files.map((value, index) => {
            return (
              <div className="description">
                <a
                  id={index + value.name}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fileDownload"
                  href={value.fullpath}
                >
                  {value.name}
                </a>
              </div>
            );
          });
      }

      viewMessageContent = (
        <div className="viewMessageContent">
          <Modal
            key={this.props.htmlid + this.props.type + "modal"}
            size="md"
            centered
            show={true}
            onHide={this.handleCloseMessage.bind(this)}
            aria-labelledby="messageModal"
            className="fileViewer"
          >
            <Modal.Header closeButton>
              Attached files
            </Modal.Header>
            <Modal.Body>
              {this.props.text}
              {linksToFiles}
            </Modal.Body>
          </Modal>
        </div>
      );
    }
    return (
      <Fragment>
        {message}
        {viewMessageContent}
      </Fragment>
    );
  }
}
export default Message;
