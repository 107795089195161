import React, { Component, Fragment } from "react";
import Spinner from "react-bootstrap/Spinner";
import * as Survey from "survey-react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";


import Constants from "../utilities/Constants";
import SessionStoreManager from "../utilities/SessionStoreManager";
import ReactGA from "react-ga";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Auth from "../../Auth";
import { appInsightsCode, baseURL, endpoints, httpGET, httpPOST } from "../../api";

class FilterQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionnaire: {},
      responses: undefined,
      isLoading: true,
      responsesid: null,
      prepareForNavigationFuncArray: []
    };

    this.baseURL = baseURL;
    this.onValueChanged = this.onValueChanged.bind(this);
    this.prepareForNavigationContinueButton = this.prepareForNavigationContinueButton.bind(
      this
    );

    var helperArray = [];
    // We need to set function name explicitly otherwise it might be altered after .bind call (always returns "bound " and no name at all)
    helperArray.push("prepareForNavigationContinueButton");
    helperArray.push(this.prepareForNavigationContinueButton);
    this.state.prepareForNavigationFuncArray.push(helperArray);
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    this.InitData();
  }

  async InitData() {
    const auth = new Auth();
    await this.LoadQuestionnaire(
      auth,
      this.props.location.state.filterQuesitonId
    );
    await this.LoadResponse(auth);
  }

  async LoadQuestionnaire(auth, filterQuestionTileId) {
    let response = await httpGET(
      endpoints.filter(
        endpoint => endpoint.name === "GetFilterQuestionById"
      )[0],
      filterQuestionTileId,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      /* console.log(
        "GetFilterQuestionById response: " + JSON.stringify(response)
      );*/

      this.setState({
        questionnaire: response.body.questionJson,
        isLoading: false
      });
    }
  }

  async LoadResponse(auth) {
    var parameters =
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId +
      "/" +
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
        .customerId +
      "/" +
      this.props.location.state.filterQuesitonId;

    let response = await httpGET(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetFilterQuestionResponseByTaxYearIdCustomerIdAndFilterQuestionId"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      /*console.log(
        "GetFilterQuestionResponseByTaxYearIdCustomerIdAndFilterQuestionId response: " +
          JSON.stringify(response)
      );*/

      var responsedata = JSON.parse(response.body.responsesJson);
      this.setState({
        responses: responsedata,
        responsesid: response.body.id
      });
    }
  }

  onValueChanged(survey, _options) {
    // console.log(survey.data);

    this.setState({
      responses: survey.data
    });
  }

  async prepareForNavigationContinueButton() {
    const auth = new Auth();

    if (this.state.responses) {
      await this.upsertFilterQuestionResponse(auth);
      await this.upsertFilterQuestionTileResponse(auth);
    }
  }

  async upsertFilterQuestionResponse(auth) {
    //console.log("upsertFilterQuestionResponse");

    var filterQuestionResponse = {
      customerId: SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
        .customerId,
      taxYearId: SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
        .taxYearId,
      responsesJson: JSON.stringify(this.state.responses),
      filterQuestionId: this.props.location.state.filterQuesitonId
    };

    if (this.state.responsesid) {
      filterQuestionResponse.id = this.state.responsesid;
    }

    let response = await httpPOST(
      endpoints.filter(
        endpoint => endpoint.name === "UpsertFilterQuestionResponse"
      )[0],
      await auth.getToken(),
      filterQuestionResponse,
      true
    );
    if (response && response.status === 200) {
      // console.log("UpsertFilterQuestion response: " + response);
    }
  }

  async upsertFilterQuestionTileResponse(auth) {
    // console.log("upsertFilterQuestionTileResponse");

    // Check if response exists for Question Tile
    var questionTileResponse = "";
    var parameters =
      this.props.location.state.filterQuesitonTileId +
      "/" +
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId +
      "/" +
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).customerId;

    let response = await httpGET(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetFilterQuestionTileResponseByFilterQuestionTileIdTaxYearIdAndCustomerId"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      /*console.log(
        "GetFilterQuestionTileResponseByFilterQuestionTileIdTaxYearIdAndCustomerId response: " +
          JSON.stringify(response)
      );*/

      questionTileResponse = response.body;
    }

    //console.log("questionTileResponse is " + questionTileResponse);

    // Create new entry if tile response doesn't exist. No need to update anything.
    if (!questionTileResponse) {
      var filterQuestionTileResponse = {
        customerId: SessionStoreManager.getValueByKey(
          Constants.taxYearProcessKey
        ).customerId,
        taxYearId: SessionStoreManager.getValueByKey(
          Constants.taxYearProcessKey
        ).taxYearId,
        filterQuestionTileId: this.props.location.state.filterQuesitonTileId,
        selected: true
      };

      /*if (questionTileResponse) {
        filterQuestionTileResponse.id = questionTileResponse.id;
      }*/

      response = await httpPOST(
        endpoints.filter(
          endpoint => endpoint.name === "UpsertFilterQuestionTileResponse"
        )[0],
        await auth.getToken(),
        filterQuestionTileResponse,
        true
      );
      if (response && response.status === 200) {
        // console.log("UpsertFilterQuestionTileResponse response: " + response);
      }
    }
  }

  render() {
    var surveyToRender = "";

    if (!this.state.isLoading) {
      var model = new Survey.Model(this.state.questionnaire);

      surveyToRender = (
        <Survey.Survey
          model={model}
          data={this.state.responses}
          onValueChanged={this.onValueChanged}
          showNavigationButtons="none"
        />
      );
    } else {
      surveyToRender = (
        <Fragment>
          {" "}
          <div className="spinnerModal">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </Fragment>
      );
    }

    return (
      <section className="sectionContent">
        <div className="page survey">
          <header>
            <h2>Income Sources</h2>
          </header>
          <div className="pageContent">{surveyToRender}</div>
          <NavControlsGenerator
            prepareForNavigation={this.state.prepareForNavigationFuncArray}
            currentPageNavCode={70}
          ></NavControlsGenerator>
        </div>
      </section>
    );
  }
}

export default FilterQuestion;
