import React, { Component, Fragment } from "react";
import Auth from "../../Auth";
import SessionStoreManager from "../utilities/SessionStoreManager";
import Message from "./Message"
import {
  endpoints,
  httpGET
} from "../../api";
import Constants from "../utilities/Constants";
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

class TaxYearMessages extends Component {
    constructor(props) {
      super(props);
      this.state = {
          taxYear: undefined,
          taxYearMessages: undefined, 
          open:false       
      };
     
     
    }
    componentDidMount() {
    
      const auth = new Auth();
      if (
        SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey) &&
        SessionStoreManager.getValueByKey(Constants.navRulesKey) &&
        SessionStoreManager.getValueByKey(Constants.navNodesKey)
      ) {
        this.getTaxYearName(
          auth,
          this.props.taxYearId
        );
       
        //Get all of the messages for the specified tax year
        this.getMessagesForTaxYear(auth);

      }
    }
    

    async getTaxYearName(auth) {
     
      var parametersAttachment = this.props.taxYearId;
      let response = await httpGET(
        endpoints.filter(endpoint => endpoint.name === "GetTaxYear")[0],
        parametersAttachment,
        await auth.getToken(),
        true
      );
  
      if (response && response.status === 200) {
        this.setState({
          taxYear: response.body[0].fromYear + "/" + response.body[0].toYear.toString().slice(-2)
        });
      }
    }
   
    async getMessagesForTaxYear(auth){
        //GetCommunicationsByTaxYear
        //{customerId}/{taxYearId}
        
        var parametersAttachment = this.props.customerId + "/" + this.props.taxYearId;
        let response = await httpGET(
          endpoints.filter(endpoint => endpoint.name === "GetCommunicationsByTaxYear")[0],
          parametersAttachment,
          await auth.getToken(),
          true
        );
    
        if (response && response.status === 200) {
         this.setState({
            taxYearMessages: response.body
          });
        }
        
    }



    render() {
     
      var messages = "";
      if(this.state.taxYearMessages)
      {
          messages = this.state.taxYearMessages.map((value, index) => {        
          return ( 
             
            <Message key={value.id} htmlid={index} taxYearId={this.props.taxYearId} customerId={value.customerId} type={value.type} title={value.title} files={value.associatedFiles} date={value.createdDate} description={value.description} from={value.type} />              
                
          );
          });
      }      
      
      return (
        <Fragment>
          <Card>
        <Card.Header>
        <Accordion.Toggle variant="link" eventKey={this.props.eventKey}>
          {this.state.taxYear}
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={this.props.eventKey}>
      <Card.Body><ul className="commsPanel">{messages}</ul>
</Card.Body>
          </Accordion.Collapse>
          </Card>
          </Fragment>
       );
      }
    }
  export default TaxYearMessages;
  