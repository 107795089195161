import React, { Component, Fragment } from "react";
import Spinner from "react-bootstrap/Spinner";
import { MsalAuthenticationTemplate, MsalContext } from "@azure/msal-react";
import { InteractionType, InteractionStatus } from "@azure/msal-browser";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

import RoutesRenderer from "./components/utilities/RoutesRenderer";

import Constants from "./components/utilities/Constants.js";
import SessionStoreManager from "./components/utilities/SessionStoreManager.js";
import { authenticationParameters } from "./components/utilities/authProvider";

import "./App.css";
import "./styles/MarketingHomePage.css";
import "./styles/timeline.css";
import "./styles/hamburgers.css";
import Auth from "./Auth";
import Header from "./components/header";
import Footer from "./components/footer";
import { baseURL, endpoints, httpGET, httpPOST, gaCode, includeMedical} from "./api";

class App extends Component {
  static contextType = MsalContext;

  constructor() {
    super();
    this.state = {
      currentUserB2C: undefined,
      currentUserCosmosDB: undefined,
      currentTaxYearProcess: undefined,
      navigationNodes: undefined,
      settings: undefined,
      navigationRules: undefined,
      navReady: false,
      navigationValidated: false,
      blobContainerUri: undefined,
      isMedical: false,
      medicalCallToAction: false
    };
    this.baseURL = baseURL;
    this.gaCode = gaCode;
  }
  componentDidMount() {
    this.ConfigureGoogleAnalytics();
    if (!this.props.location.hash) {
      this.initialise();
    }
    else {
      var auth = new Auth();
      // if there's a hash we are logging on, so we need to
      // initialise after the login is complete
      auth.onLogin(this.initialise, this);
    }
   
  }

  initialise() {
    this.InitDataAndCache();
    this.getNavigationNodes();
    
  }

  async ConfigureGoogleAnalytics() {
    const trackingId = gaCode;
    ReactGA.initialize(trackingId);
  }
  async getAllMedicalExpensesProcesses() {
    const auth = new Auth();
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    if (currentUser !== null){
        var parameters =
        currentUser.id;

      let response = await httpGET(
        endpoints.filter((endpoint) => endpoint.name === "GetMedicalExpenses")[0],
        parameters,
        await auth.getToken(),
        true
      );

      if (response && response.status === 200) {
        
        if (response.body.length > 0)
        {
          this.setState({
            isMedical: true,
          });
        }
        var callToActionOpen = response.body.filter(function (process) {
          return process.status === 'Open';
        });
        var callToActionStarted = response.body.filter(function (process) {
        return process.status === 'Started';
        });
      if (callToActionOpen.length > 0 || callToActionStarted.length > 0 ) {
        this.setState({
          medicalCallToAction: true,
        });
      }
      }
    }
   
  }
  async getNavigationNodes() {
    const auth = new Auth();

    await this.getAllNavigationNodes(auth);
  }

  async getAllNavigationRulesByCustomerType(auth, customerTypeId) {
    if (SessionStoreManager.getValueByKey(Constants.navRulesKey)) {
      this.setState({
        navigationRules: SessionStoreManager.getValueByKey(
          Constants.navRulesKey
        )
      });
    } else {
      let response = await httpGET(
        endpoints.filter(
          endpoint => endpoint.name === "GetAllNavigationRulesByUserType"
        )[0],
        customerTypeId,
        await auth.getToken(),
        true
      );

      if (response && response.status === 200) {
        /*console.log(
          "GetAllNavigationRulesByUserType response: " +
            JSON.stringify(response)
        );*/

        SessionStoreManager.setValueForKey(
          Constants.navRulesKey,
          response.body
        );

        this.setState({
          navigationRules: response.body
        });
      }
    }
  }

  async getAllNavigationNodes(auth) {
    if (SessionStoreManager.getValueByKey(Constants.navNodesKey)) {
      this.setState({
        navigationNodes: SessionStoreManager.getValueByKey(
          Constants.navNodesKey
        ),
        navReady: true
      });
      // console.log("cache ready");
    } else {
      let response = await httpGET(
        endpoints.filter(
          endpoint => endpoint.name === "GetAllNavigationNodes"
        )[0],
        "",
        await auth.getToken(),
        true
      );

      if (response && response.status === 200) {
        //   console.log("GetAllNavigationNodes response: " + response);

        SessionStoreManager.setValueForKey(
          Constants.navNodesKey,
          response.body
        );

        this.setState({
          navigationNodes: response.body,
          navReady: true
        });

        //   console.log("cache ready");
      }
      //If the customer is trying to access any route that is not
      // authorised, they should be redirected to a valid route to ensure that
      // their use journey works as expected after they authenticate. 
      if (response && response.status === 401) {
        this.props.history.push("/mytaxreturns");
      }
      if (response == null)
      {
        this.props.history.push("/mytaxreturns");
      }
    }
  }

  async getSettings(auth) {
    if (SessionStoreManager.getValueByKey(Constants.settingsCosmosDBKey)) {
      this.setState({
        settings: SessionStoreManager.getValueByKey(
          Constants.settingsCosmosDBKey
        )
      });
    } else {
      let response = await httpGET(
        endpoints.filter(endpoint => endpoint.name === "GetSettings")[0],
        "",
        await auth.getToken(),
        true
      );

      if (response && response.status === 200) {
        // console.log("GetSettings response: " + response);

        SessionStoreManager.setValueForKey(
          Constants.settingsCosmosDBKey,
          response.body
        );

        this.setState({
          settings: response.body
        });
      }
    }
  }

  async InitDataAndCache() {
    const auth = new Auth();
    if (auth.isLoggedIn()) {
      if (SessionStoreManager.getValueByKey(Constants.currentUserB2CKey)) {
        this.setState({
          currentUserB2C: SessionStoreManager.getValueByKey(
            Constants.currentUserB2CKey
          )
        });
      } else {
        //    console.log(await auth.currentUser(baseURL));

        this.setState({
          currentUserB2C: await auth.currentUser(baseURL)
        });

        SessionStoreManager.setValueForKey(
          Constants.currentUserB2CKey,
          await auth.currentUser(baseURL)
        );
      }

      await this.getUsr(auth);

      await this.getSettings(auth);

      if (this.state.currentUserCosmosDB) {
        // Also start a new tax year process if it doesn't exist already
        var taxYearProcess = {
          CustomerId: this.state.currentUserCosmosDB.id,
          TaxYearId: SessionStoreManager.getValueByKey(
            Constants.settingsCosmosDBKey
          ).currentTaxYearId
        };

        await this.getOrCreateTaxYearProcess(auth, taxYearProcess);

        await this.getAllNavigationRulesByCustomerType(
          auth,
          this.state.currentUserCosmosDB.customerTypeId
        );

        await this.createBlobContainerForCustomer(
          auth,
          this.state.currentUserCosmosDB.id
        );
        //DAN's DEV WHEN DONE
        /*this.setState({
  navigationValidated: true
});*/
        this.navigationValidator();
        
             this.getAllMedicalExpensesProcesses();
       
     
      } else {
        //  console.log("Couldn't fetch user from CosmosDB");
      }
    }
  }

  navigationValidator() {
    // Check if authentication token is till valid and redirect to login page is it isn't
    /* const auth = new Auth();
    if (!auth.isTokenValid()) {
      auth.logout();
    }*/
    // Checks if user is allowed to be on the current page
    // Get nav node ID that corresponds to the current URL in the browser then check if ID of this node matches expected node id that the user should be on
    // Don't use state but use cache instead! THis is because currentTaxYearProcess in the state is not updated when the entry is updated in the database but
    // session storage is updated
    var currentNavNode = SessionStoreManager.getValueByKey(
      Constants.navNodesKey
    ).filter(navNode => navNode.path === this.props.location.pathname.replace(/\/+$/, ''))[0];
    //console.log(currentNavNode);
    if(!currentNavNode && this.props.location.pathname.startsWith("/medicalquestionnaire")){
      currentNavNode = SessionStoreManager.getValueByKey(
        Constants.navNodesKey
      ).filter(navNode => navNode.path.startsWith("/medicalquestionnaire"))[0];
    }
    if (currentNavNode) {
      if (
        currentNavNode.code ===
        SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
          .currentNavNodeCode
      ) {
        // console.log("Staying on the current page");
      } else {
        var newNavNode = SessionStoreManager.getValueByKey(
          Constants.navNodesKey
        ).filter(
          navNode =>
            navNode.code ===
            SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
              .currentNavNodeCode
        )[0];
        //  console.log("Need to redirect to node with Code " + newNavNode.code);
        this.props.history.push(newNavNode.path);
      }
    } else {
      /* console.log(
        "Can't identify nav node for " +
          window.location.href +
          " Redirecting to 'page not found'"
      );*/
        this.props.history.push("/pagenotfound");
    }

    this.setState({
      navigationValidated: true
    });
  }

  async createBlobContainerForCustomer(auth, customerId) {
    if (SessionStoreManager.getValueByKey(Constants.blobContainerUri)) {
      this.setState({
        blobContainerUri: SessionStoreManager.getValueByKey(
          Constants.blobContainerUri
        )
      });
    } else {
      let response = await httpPOST(
        endpoints.filter(
          endpoint => endpoint.name === "CreateBlobContainerForCustomer"
        )[0],
        await auth.getToken(),
        customerId,
        true
      );
      if (response && response.status === 200) {
        /*  console.log(
          "createBlobContainerForCustomer: " + JSON.stringify(customerId)
        );
        console.log(
          "createBlobContainerForCustomer response: " + response.body
        );*/

        SessionStoreManager.setValueForKey(
          Constants.blobContainerUri,
          response.body
        );
      }

      this.setState({
        blobContainerUri: response.body
      });
    }
  }

  async getOrCreateTaxYearProcess(auth, taxYearProcess) {
    if (SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)) {
      this.setState({
        currentTaxYearProcess: SessionStoreManager.getValueByKey(
          Constants.taxYearProcessKey
        )
      });
    } else {
      let response = await httpPOST(
        endpoints.filter(
          endpoint => endpoint.name === "GetOrCreateTaxYearProcess"
        )[0],
        await auth.getToken(),
        taxYearProcess,
        true
      );
      if (response && response.status === 200) {
        /*  console.log(
          "getOrCreateTaxYearProcess: " + JSON.stringify(taxYearProcess)
        );
        console.log("getOrCreateTaxYearProcess response: " + response);
*/
        SessionStoreManager.setValueForKey(
          Constants.taxYearProcessKey,
          response.body
        );

        this.setState({
          currentTaxYearProcess: response.body
        });
      }
    }
  }

  async getUsr(auth) {
    if (SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey)) {
      this.setState({
        currentUserCosmosDB: SessionStoreManager.getValueByKey(
          Constants.currentUserCosmosDBKey
        )
      });
    } else {
      let payload = await auth.currentUser(baseURL);
      let userEmail = payload.email;
      let response = await httpGET(
        endpoints.filter(endpoint => endpoint.name === "GetCustomer")[0],
        userEmail,
        await auth.getToken(),
        true
      );
      if (response && response.status === 200) {
        /*  console.log(
          "GetOrCreateCustomer response: " + JSON.stringify(response)
        );*/

        SessionStoreManager.setValueForKey(
          Constants.currentUserCosmosDBKey,
          response.body
        );

        let customer = response.body;
        if (response.body.signUpCompleteDate == null) {
          var today = new Date();
          customer.signUpCompleteDate = today.toISOString();
          let responseOfUpdate = await httpPOST(
            endpoints.filter(endpoint => endpoint.name === "UpdateCustomer")[0],
            await auth.getToken(),
            customer,
            true
          );

          if (responseOfUpdate && responseOfUpdate.status === 200) {
            SessionStoreManager.setValueForKey(
              Constants.currentUserCosmosDBKey,
              responseOfUpdate.body
            );
          }
        }

        this.setState({
          currentUserCosmosDB: response.body
        });
      }
    }
  }

  displayUserInformation() {
    if (this.state.currentUserB2C) {
      //  console.log(this.state.currentUserB2C);

      return (
        <div>
          <p>
            Welcome, {this.state.currentUserB2C.firstName}{" "}
            {this.state.currentUserB2C.lastName}
          </p>
          <a className="App-link" href="/abc" onClick={() => this.logout()}>
            Sign Out
          </a>
        </div>
      );
    } else {
      return <Fragment>Loading...</Fragment>;
    }
  }

  logout() {
    const auth = new Auth();
    auth.logout();
  }

  render() {
    if (this.state.navigationValidated) {
      return (
        <>
          <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authenticationParameters}
          >
            <div className="App">
              <Header tier={this.state.currentTaxYearProcess.tier} isMedical={this.state.isMedical} medicalCallToAction={this.state.medicalCallToAction} />
              <article className="contentWrapper">
                <RoutesRenderer
                  navReady={this.state.navReady}
                  navigationNodes={this.state.navigationNodes}
                ></RoutesRenderer>
              </article>
              <Footer />
            </div>
          </MsalAuthenticationTemplate>
        </>
      );
    } else {
      return (
        <>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authenticationParameters}
        >
          <div className="spinnerModal">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          </MsalAuthenticationTemplate>
        </>
      );
    }
  }
}

export default withRouter(App);
