import React, { Component } from "react";
import NavControlsGenerator from "../utilities/NavControlsGenerator";
import ReactGA from "react-ga";
import { appInsightsCode } from "../../api";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Auth from "../../Auth.js";
import Constants from "../utilities/Constants";
import SessionStoreManager from "../utilities/SessionStoreManager";
import { baseURL, endpoints, httpGET } from "../../api";

class Introduction extends Component {
  constructor(props) {
    super(props);
    this.counter = 1;

    this.state = {
      taxYear: undefined
    };
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    const auth = new Auth();
    this.getTaxYearName(auth);
  }
  async getTaxYearName(auth) {
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );

    var parametersAttachment = currentTaxProcess.taxYearId;
    let response = await httpGET(
      endpoints.filter(endpoint => endpoint.name === "GetTaxYear")[0],
      parametersAttachment,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      this.setState({
        taxYear:
          response.body[0].fromYear +
          "/" +
          response.body[0].toYear.toString().slice(-2)
      });
    }
  }
  /*onGoTo = dir => this.ts.slider.goTo(dir);
  isItTheLastQuestion() {
    this.onGoTo("next");
    var info = this.ts.slider.getInfo();

    //Last slide as it's not in bucle, index will never be the same than slideCount
    if (this.counter === info.slideCount) {
      return true;
    } else {
      this.counter++;
      return false;
    }
  }*/
  render() {
    const settings = {
      lazyload: true,
      nav: true,
      navPosition: false,
      controls: false,
      mouseDrag: true,
      loop: false
    };
    const survey = {
      questions: [
        {
          title: this.state.taxYear + " Tax Return Questionnaire",
          description:
            "On the next screen we’ll check what sources of income you had last year so we can tailor your questionnaire.  We’ve indicated [in blue] the income sources you had per your tax return last year but simply un-highlight any which are not applicable for " +
            this.state.taxYear +
            " and highlight any new income sources for " +
            this.state.taxYear +
            ".  This will ensure you see the correct questions on subsequent pages."
        }
      ]
    };

    return (
      <section className="sectionContent">
        <div className="page introPage">
          <div className="pageContent">
            <h2>BDO Personal tax returns</h2>
            <h3>How it works</h3>
            <section className="howtoGuide">
              <ul>
                <li>
                  We’ll check with you what sources of income you had for{" "}
                  <span>{this.state.taxYear}</span>.
                </li>
                <li>
                  We’ll ask you some detailed questions
                  <span className="asterisk">*</span> about each income source
                  and let you know what documents to refer to in order to answer
                  those questions as you go along.
                </li>
                <li>
                  For your ease of reference, certain fields may have been pre-populated
                  based on information we hold for you from prior tax returns (personal
                  details, bank account details, rental property addresses, etc).  The
                  pre-populated data can be over-written if appropriate or deleted if no
                  longer required.
                </li>
                <li>
                  If you get a bit stuck along the way just contact your usual
                  BDO tax adviser and they’ll help you to get back on track.
                </li>
                <li>
                  Once you’ve submitted the questionnaire we’ll complete your
                  return and your tax team will review it before we save it and
                  your covering letter on the portal. We’ll send you a message
                  when it’s ready for you to review.
                </li>
                <li>
                  Where under agreement we have historically obtained certain documents directly on your behalf (eg where we produce the P60/P11D or partnership return within BDO) then you should still select the category on the next screen, but you’ll be given the option to tell us that “BDO obtains the document directly” at the relevant question.  If you have any doubt about which documents we normally obtain on your behalf, please feel free to contact your usual BDO tax team who will be happy to clarify.
                </li>
                <li>
                  The tax system places considerable responsibility on taxpayers to 
                  maintain records and to correctly complete their tax returns in 
                  good time. HMRC has a wide range of powers to check that a taxpayer’s 
                  returns are correct. These range from enquiries into aspects of a return,
                  or into a taxpayer’s affairs generally, to visits to business
                  premises to inspect business records.
                </li>
              </ul>
            </section>
            <section className="howtoNotes">
              <ul>
                <li>
                  Please only include your share of income and gains from
                  sources held in joint names.
                </li>
              </ul>
            </section>
            <NavControlsGenerator
              currentPageNavCode={190}
            ></NavControlsGenerator>
          </div>
        </div>
      </section>
    );
  }
}

export default Introduction;
