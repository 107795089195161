import React, { Fragment } from "react";
import * as Survey from "survey-react";
import Auth from "../../Auth";
import {
  baseURL,
  endpoints,
  httpGET,
  httpGETByArray,
  appInsightsCode,
  ocrDocumentsSurveyTag,
  ocrDocumentTargetFolder,
  investmentsOcrDocumentsSurveyTag,
  investmentsOcrDocumentTargetFolder
} from "../../api";
import SessionStoreManager from "../utilities/SessionStoreManager";
import Constants from "../utilities/Constants";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from "react-ga";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
/**
 * This component renders a questionnaire (using the Survey js component) for the given customer along with any previously recorded responses.
 * The user can save at any pont and submit their completed survey after they have completed all mandatory questions.
 * @returns {jsx} the survey component or a loading message
 */
class ReadOnlyQuestionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionnaire: {},
      questionTileResponse: {},
      isLoading: true,
      customerid: undefined,
      responsesid: null,
      responses: {},
      taxYearId: null,
      taxYearProcess: null,
      questionnaireNav: undefined,
      currentPage: null,
      sectionNavHeaderClassName: "sectionNavHeader_collapsed",
      tier: undefined
    };

    this.baseURL = baseURL;
    this.questionnaireVersionArr = [];
    this.onAfterRenderQuestion = this.onAfterRenderQuestion.bind(this);
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode
      }
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();
    const auth = new Auth();
    if (
      SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey) &&
      SessionStoreManager.getValueByKey(Constants.navRulesKey) &&
      SessionStoreManager.getValueByKey(Constants.navNodesKey)
    ) {
      var currentUser = SessionStoreManager.getValueByKey(
        Constants.currentUserCosmosDBKey
      );
      this.setState({
        customerid: currentUser.id,
        taxYearId: this.props.taxYearId,
        taxYearProcess: this.props.taxYearProcess
      });
      this.LoadResponse(auth);
    }
  }
  async onAfterRenderQuestion(survey, options) {
    if (options.question.getType() === 'file' && options.question.value && options.question.value !== '') {
     
      var i;
      var SAS;
      for (i = 0; i < options.question.value.length; i++) {  
        SAS = await this.getSAS(options.question.value[i].name, options);        
          options.htmlElement.innerHTML += '<div><a target="_blank" rel="noopener noreferrer" class="fileDownload" href="' + SAS + '">Download "' + options.question.value[i].name + '" </a></div>';
         
      }
      
  }
}
  
async getSAS(fileName, options) {
  const auth = new Auth();
  var currentUser = SessionStoreManager.getValueByKey(
    Constants.currentUserCosmosDBKey
  );
  var parametersAttachment = undefined;
  
  if (options.question.name.includes(investmentsOcrDocumentsSurveyTag))
  {
    //containerName}/{taxYearid}/{folder}/{blobName}
    parametersAttachment =
    currentUser.id +
      "/" +
      this.props.taxYearId +
      "/" +
      investmentsOcrDocumentTargetFolder +
      "/" +
      fileName ;
  }
  else
  {
    if (options.question.name.includes(ocrDocumentsSurveyTag))
  {
    //containerName}/{taxYearid}/{folder}/{blobName}
    parametersAttachment =
    currentUser.id +
      "/" +
      this.props.taxYearId +
      "/" +
      ocrDocumentTargetFolder +
      "/" +
      fileName ;
  }
  else
  {
      //containerName}/{blobName}/{taxYearid}
    parametersAttachment =
  currentUser.id +
    "/" +
    fileName +
    "/" +
    this.props.taxYearId ;
  }
  
  }
  let response = await httpGET(
    endpoints.filter(
      endpoint => endpoint.name === "GetAttachmentURLFromBlobContainer"
    )[0],
    parametersAttachment,
    auth.getToken(),
    false
  );

  if (response && response.status === 200) {
   
     return response.body;
   
  }
  else
  {
    return "";
  }
}

  async LoadResponse(auth) {
    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var currentTaxProcess = this.props.taxYearProcess;
    //Return the questionnaire from the user's process
    var version = currentTaxProcess.questionnaireVersion;
    var parameters =
      this.props.taxYearId + "/" + currentUser.id + "/" + version;

    let response = await httpGET(
      endpoints.filter(endpoint => endpoint.name === "GetResponse")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      // console.log("GetResponse response: " + response.statusText);
      var responsedata = JSON.parse(response.body.responsesJson);

      this.setState({
        responses: responsedata.data,
        responsesid: response.body.id,
        tier: response.body.tier
      });
      this.LoadQuestionnaire(auth);
    }
    if (response && response.status === 204) {
      //none stored for this user so use default questionnaire version
      // this.LoadQuestionnaire(auth);
    }
  }
  async LoadQuestionnaire(auth) {
    var parametersQuestionnaire =
      this.props.taxYearId +
      "/" +
      this.props.taxYearProcess.questionnaireVersion;

    let response = await httpGET(
      endpoints.filter(endpoint => endpoint.name === "GetQuestionnaire")[0],
      parametersQuestionnaire,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      //  console.log("GetQuestionnaire response: " + response);
      // load the correct nav
      this.setState({
        questionnaire: response.body.questionnaireJson,
        questionnaireNav: JSON.parse(response.body.questionnaireNav)
      });
        this.LoadFilterQuestionTiles(auth);
    }
  }
  clickNav(pageid){
    //alert(pageid);
    this.setState({
      currentPage: pageid,
      sectionNavHeaderClassName: "sectionNavHeader_collapsed"
   });
  }
  clickHeader(){
    if (this.state.sectionNavHeaderClassName === "sectionNavHeader_collapsed"){
      this.setState({
        sectionNavHeaderClassName: "sectionNavHeader_expanded"
     });
    }
    else
    {
      this.setState({
      sectionNavHeaderClassName: "sectionNavHeader_collapsed"
      });

    }
  }
  async LoadFilterQuestionTiles(auth) {
    if (this.props.taxYearProcess.questionnaireVersion) {
      var fqTileIdArr = [];
      var currentTaxProcess = this.props.taxYearProcess;
      var currentUser = SessionStoreManager.getValueByKey(
        Constants.currentUserCosmosDBKey
      );
      var parameters = currentTaxProcess.taxYearId + "/" + currentUser.id;

      let response = await httpGET(
        endpoints.filter(
          endpoint =>
            endpoint.name ===
            "GetFilterQuestionTileResponseByTaxYearIdAndCustomerId"
        )[0],
        parameters,
        await auth.getToken(),
        true
      );

      if (response && response.status === 200) {
        /* console.log(
          "GetFilterQuestionTileResponseByTaxYearIdAndCustomerId response: " +
            JSON.stringify(response.body)
        );*/
        response.body.forEach(e => {
          fqTileIdArr.push(e.filterQuestionTileId);
          this.LoadQuestionTileQuestionnaireVersion(
            e.filterQuestionTileId,
            auth,
            fqTileIdArr
          );
        });
        this.LoadIncomeSourcesToQuestionnaire(
          currentTaxProcess.questionnaireVersion,
          auth,
          fqTileIdArr,
          this.state.tier
        );
        //this.GetFilterQuestionTileId(auth);
      }
    }
  }

  async LoadQuestionTileQuestionnaireVersion(filterQuestionTileId, auth) {
    let questionnaireVersion = await httpGET(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetFilterQuestionTileQuestionnaireVersionByFilterQuestionTileId"
      )[0],
      filterQuestionTileId,
      await auth.getToken(),
      true
    );
    if (questionnaireVersion && questionnaireVersion.status === 200) {
     /*  console.log(
        "GetFilterQuestionTileQuestionnaireVersionByFilterQuestionTileId response: " +
          JSON.stringify(questionnaireVersion.body)
      ); */
      this.questionnaireVersionArr.push(
        questionnaireVersion.body[0].questionnaireVersion
      );
    }
  }

  async LoadIncomeSourcesToQuestionnaire(
    questionnaireVersion,
    auth,
    filterQuestionTileId,
    tier
  ) {
    var concat = "str='";
    var count = 0;
    filterQuestionTileId.forEach(e => {
      if (count < filterQuestionTileId.length - 1) {
        concat += e + "'&str='";
      } else if (count === filterQuestionTileId.length - 1) {
        concat += e + "'";
      }
      count++;
    });
    //console.log(concat);
    var currentTaxProcess = this.props.taxYearProcess;
    var parameters =
      concat +
      "&taxYearId='" +
      currentTaxProcess.taxYearId +
      "'&questionnaireVersion='" +
      questionnaireVersion +
      "'" +
      "'&tier='" +
      tier +
      "'";

    let response = await httpGETByArray(
      endpoints.filter(
        endpoint =>
          endpoint.name ===
          "GetIncomeSourcesToQuestionnaireByFilterQuestionTileIdTaxYearIdAndquestionnaireVersion"
      )[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      /*  console.log(
        "GetIncomeSourcesToQuestionnaireByFilterQuestionTileIdTaxYearIdAndquestionnaireVersion response: " +
          JSON.stringify(response)
      );*/
      this.setState({
        questionTileResponse: response.body,
        isLoading: false
      });
    }
  }
  getClassName(pageId){
    if (pageId === this.state.currentPage)
    {
      return "menuItem active";
    }
    else
    {
      return "menuItem";
    }
  }
  render() {
    var surveyToRender = "";
    var navigationToRender = "";
    var page = null;
    if (!this.state.isLoading) {
      var model = new Survey.Model(this.state.questionnaire);

      Survey.surveyLocalization.locales[
        Survey.surveyLocalization.defaultLocale
      ].cleanCaption = "Remove all";
      Survey.StylesManager.applyTheme("bootstrap");

      var myCss = {
        matrix: {
          root: "table table-striped"
        },
        navigationButton: "button btn-lg"
      };
      var navHTML = "";
      var navForTheQuestionnaire = this.state.questionnaireNav;
      window.survey = new Survey.Model(this.surveyJSON);
      if (Object.keys(this.state.questionTileResponse).length > 0) {
      this.state.questionTileResponse.forEach(element => {
        page = model.getPageByName(element.pageId);
        model.removePage(page);
        if (navForTheQuestionnaire !== undefined &&  navForTheQuestionnaire !== null){
          navForTheQuestionnaire = navForTheQuestionnaire.filter(function(Navpage) {
           return Navpage.pageId !== element.pageId;
         });
       }
      });
      if (navForTheQuestionnaire !== undefined &&  navForTheQuestionnaire !== null){
      navHTML = navForTheQuestionnaire.map((item, key) => (
        <div key={key} className="menuItem">
          <span onClick={() => { this.clickNav(item.pageId)}}>{item.title}</span>
        </div>
        
      ));
      }
      if (navForTheQuestionnaire !== undefined &&  navForTheQuestionnaire !== null){
      navigationToRender = (
        <section className="sectionNav">
      <header className={this.state.sectionNavHeaderClassName}  onClick={() => { this.clickHeader()}}>
      <div className="sectionsIcon"></div>
        <h2>Questionnaire sections</h2>
      </header>
      <nav className="surveyNav">
       {navHTML}
      </nav>
    </section>

      );
      }
    }
    else
    {
      //All selected we need to show the whole nav bar
      if (navForTheQuestionnaire !== undefined &&  navForTheQuestionnaire !== null){
       
        navHTML = navForTheQuestionnaire.map((item, key) => (         
          <div key={key} className="menuItem">
            <span onClick={() => { this.clickNav(item.pageId)}}>{item.title}</span>
          </div>
          
        ));
        }
        if (navForTheQuestionnaire !== undefined &&  navForTheQuestionnaire !== null){
        navigationToRender = (
          <section className="sectionNav">
        <header className={this.state.sectionNavHeaderClassName}  onClick={() => { this.clickHeader()}}>
          <div className="sectionsIcon"></div>
          <h2>Questionnaire sections</h2>
        </header>
        <nav className="surveyNav">
         {navHTML}
        </nav>
      </section>

        );
        }
    }
      var mode = "display";
      surveyToRender = (
        <Survey.Survey
          mode={mode}
          model={model}
          data={this.state.responses}
          currentPage={this.state.currentPage}
          css={myCss}          
          onAfterRenderQuestion={this.onAfterRenderQuestion}
        />
      );
    } else {
      surveyToRender = (
        <Fragment>
          <div className="spinnerModal">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {navigationToRender}
        <section className="sectionContent">
          <div className="page survey readOnly">{surveyToRender}</div>
        </section>
      </Fragment>
    );
  }
}
ReadOnlyQuestionnaire.propTypes = {};
export default ReadOnlyQuestionnaire;
