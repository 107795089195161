import React, { Component, Fragment } from "react";
import Auth from "../../Auth";
import SessionStoreManager from "../utilities/SessionStoreManager";
import {
  baseURL,
  endpoints,
  httpGET,
  httpPOST,
  httpPUT,
  appInsightsCode
} from "../../api";
import Constants from "../utilities/Constants";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactGA from "react-ga";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

class TaxReturnApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerid: undefined,
      transmittalLetterURL: undefined,
      taxreturnURL: undefined,
      amendedTaxReturnURL: undefined,
      isLoading: false,
      clientApprovesClick: false,
      taxYear: undefined,
      lastTaxYear: undefined,
      showAmendsForm: false,
      message: "",
      NavNode: 200
    };
    this.baseURL = baseURL;
    this.handleRequestChanges = this.handleRequestChanges.bind(this);
    this.handleAmendsSubmit = this.handleAmendsSubmit.bind(this);
    this.handleAmendsCancel = this.handleAmendsCancel.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleAlertConfirmationConfirm = this.handleAlertConfirmationConfirm.bind(
      this
    );
    this.handleAlertConfirmationCancel = this.handleAlertConfirmationCancel.bind(
      this
    );
    this.onMessageChange = this.onMessageChange.bind(this);
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsCode
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    const auth = new Auth();
    if (
      SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey) &&
      SessionStoreManager.getValueByKey(Constants.navRulesKey) &&
      SessionStoreManager.getValueByKey(Constants.navNodesKey) &&
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
    ) {
      this.getTaxYearName(
        auth,
        SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId
      );
      this.getBlobLinkTaxReturn(auth, "TaxReturns");

      this.getBlobLinkTransmittalLetter(auth, "CoverLetter");

      this.getBlobLinkAmendedTaxReturn(auth, "AmendedTaxReturns");
    }
  }
  async getTaxYearName(auth, taxYearId) {
    var parametersAttachment = taxYearId;
    let response = await httpGET(
      endpoints.filter(endpoint => endpoint.name === "GetTaxYear")[0],
      parametersAttachment,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
      var lastFromYear = (parseInt(response.body[0].fromYear) - 1).toString()
      var lastToYear = (parseInt(response.body[0].toYear) - 1).toString().slice(-2);
      this.setState({
        taxYear: response.body[0].fromYear + "/" + response.body[0].toYear.toString().slice(-2),
        lastTaxYear: lastFromYear + "/" + lastToYear
      });
    }
  }
  async getBlobLinkTaxReturn(auth, foldername) {
    var parametersAttachment =
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
        .customerId +
      "/" +
      foldername +
      "/" +
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId +
      "/true/true";
    let response = await httpGET(
      endpoints.filter(
        endpoint => endpoint.name === "GetAttachmentURLFromBlobContainer"
      )[0],
      parametersAttachment,
      await auth.getToken(),
      false
    );

    if (response && response.status === 200) {
      this.setState({
        taxreturnURL: response.body
      });
    }
  }

  async getBlobLinkAmendedTaxReturn(auth, foldername) {
    var parametersAttachment =
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
        .customerId +
      "/" +
      foldername +
      "/" +
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId +
      "/true/true";
    let response = await httpGET(
      endpoints.filter(
        endpoint => endpoint.name === "GetAttachmentURLFromBlobContainer"
      )[0],
      parametersAttachment,
      await auth.getToken(),
      false
    );

    if (response && response.status === 200) {
      this.setState({
        amendedTaxReturnURL: response.body
      });
    }
  }

  async getBlobLinkTransmittalLetter(auth, foldername) {
    var parametersAttachment =
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey)
        .customerId +
      "/" +
      foldername +
      "/" +
      SessionStoreManager.getValueByKey(Constants.taxYearProcessKey).taxYearId +
      "/true/true";
    let response = await httpGET(
      endpoints.filter(
        endpoint => endpoint.name === "GetAttachmentURLFromBlobContainer"
      )[0],
      parametersAttachment,
      await auth.getToken(),
      false
    );

    if (response && response.status === 200) {
      this.setState({
        transmittalLetterURL: response.body
      });
    }
  }
  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }
  async handleAmendsSubmit() {
    const auth = new Auth();

    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    var requestToPost = {
      message: this.state.message,
      TaxYearId: currentTaxProcess.taxYearId,
      customerId: currentUser.id
    };
    let response = await httpPOST(
      endpoints.filter(
        endpoint => endpoint.name === "SendAmendmentsRequestEmail"
      )[0],
      await auth.getToken(),
      requestToPost,
      true
    );
    if (response && response.status === 200) {
      // console.log("send email status: " + response.body);

      //Now update status
      this.setState({
        showAmendsForm: false
      });
      currentTaxProcess.status = "40";
      currentTaxProcess.reviewStatus = "Rejected";
      //Set value in cache
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        currentTaxProcess
      );
      await this.UpdateTaxYearProccess(auth, currentTaxProcess);

      window.location.reload();
    } else {
      alert("Unable to process request");
    }
  }

  handleRequestChanges() {
    this.setState({
      showAmendsForm: true
    });
  }
  handleApprove() {
    this.setState({
      clientApprovesClick: true
    });
  }
  async handleAlertConfirmationConfirm() {
    const auth = new Auth();

    var currentUser = SessionStoreManager.getValueByKey(
      Constants.currentUserCosmosDBKey
    );
    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    var requestToPost = {
      TaxYearId: currentTaxProcess.taxYearId,
      customerId: currentUser.id
    };
    let response = await httpPOST(
      endpoints.filter(endpoint => endpoint.name === "SendApprovalEmail")[0],
      await auth.getToken(),
      requestToPost,
      true
    );
    if (response && response.status === 200) {
      this.setState({
        clientApprovesClick: false
      });
      // console.log("send email status: " + response.body);
      //Now update status
      currentTaxProcess.status = "60";
      currentTaxProcess.reviewStatus = "Approved";
      //Set value in cache
      SessionStoreManager.setValueForKey(
        Constants.taxYearProcessKey,
        currentTaxProcess
      );
      await this.UpdateTaxYearProccess(auth, currentTaxProcess);
      window.location.reload();
    } else {
      alert("Unable to process request");
    }
  }
  handleAmendsCancel() {
    this.setState({
      showAmendsForm: false,
      message: ""
    });
  }
  handleAlertConfirmationCancel() {
    this.setState({
      clientApprovesClick: false
    });
  }
  async UpdateTaxYearProccess(auth, currentTaxProcess) {
    let response = await httpPUT(
      endpoints.filter(
        endpoint => endpoint.name === "GetOrCreateTaxYearProcess"
      )[0],
      currentTaxProcess.id,
      await auth.getToken(),
      currentTaxProcess
    );
    if (response && response.status === 200) {
      /* console.log(
      "updating taxyear porcess: " + JSON.stringify(currentTaxProcess)
    );
    console.log("getOrCreateTaxYearProcess response: " + response);    */
    }
  }
  render() {
    // get tax return documents
    var taxReturnToRender = "";
    var letterToRender = "";
    var amendedToRender = "";
    var amendedFound = false;
    var renderConfirmation = "";
    var taxReturnYear = "";
    var lastTaxReturnYear = "";
    var renderAmendsForm = "";
    var taxReturnImgLink = "";
    var letterImgLink = "";
    var amendedImgLink = "";
    var buttons = "";

    var currentTaxProcess = SessionStoreManager.getValueByKey(
      Constants.taxYearProcessKey
    );
    if (currentTaxProcess.reviewStatus === "Rejected") {
      buttons = (
        <p>
          We have received your query and will respond shortly.
        </p>
      );
    }

    if (currentTaxProcess.status === "50") {
      buttons = (
        <Fragment>
          <button
            className="button queryButton"
            value="Request changes"
            onClick={this.handleRequestChanges}
          >Query
          </button>
          <button className="button" onClick={this.handleApprove}>
            Approve
          </button>
        </Fragment>
      );
    }
    if (this.state.showAmendsForm) {
      renderAmendsForm = (
        <div>
          <Modal
            size="xl"
            centered
            show={true}
            onHide={this.handleAmendsCancel.bind(this)}
            aria-labelledby="example-custom-modal-styling-title"
            className="requestChanges"
          >
            <Modal.Header closeButton>
              Query
            </Modal.Header>
            <Modal.Body>
           <h3>If you have any additional information for us, would like to request a change or have a query about your draft tax return then please let us know in the box below.</h3>
              <textarea
                rows="10"
                maxLength="10000"
                onChange={this.onMessageChange}
                placeholder="Let us know here"
              ></textarea>
            </Modal.Body>
            <Modal.Footer className="queryFooter">
            <Button
                className="button"
                onClick={this.handleAmendsCancel.bind(this)}
              >
                Cancel
              </Button>
              <Button
                className="button"
                onClick={this.handleAmendsSubmit.bind(this)}
              >
                Send
              </Button>
              
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
    if (this.state.taxYear) {
      taxReturnYear = this.state.taxYear;
    }
    if (this.state.lastTaxYear) {
      lastTaxReturnYear = this.state.lastTaxYear;
    }
    if (this.state.taxreturnURL) {
      //check to see if it exists
      if (this.state.taxreturnURL === "File not found") {
        taxReturnToRender = <Fragment>File not found</Fragment>;
        taxReturnImgLink = <div className="taxFileType fileNotFound"></div>;
      } else {
        taxReturnToRender = (
          <Fragment>
            {/* <a
              className="button"
              href={this.state.taxreturnURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>{" "} */}
          </Fragment>
        );
        taxReturnImgLink = (
          <a
            href={this.state.taxreturnURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="taxFileType pdf"></div>
          </a>
        );
      }
    }
    else
    {
      taxReturnToRender = <Fragment>File not found</Fragment>;
        taxReturnImgLink = <div className="taxFileType fileNotFound"></div>;
    }
    if (this.state.amendedTaxReturnURL) {
      if (this.state.transmittalLetterURL === "File not found") {
        amendedToRender = <Fragment>File not found</Fragment>;
        amendedImgLink = <div className="taxFileType fileNotFound"></div>;
      } else {
        amendedFound = true;
        amendedToRender = (
          <Fragment>
            {}
          </Fragment>
        );
        amendedImgLink = (
          <a
            href={this.state.amendedTaxReturnURL}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <div className="taxFileType pdf"></div>
          </a>
        );
      }
    }
    else 
    {
      amendedToRender = <Fragment>File not found</Fragment>;
      amendedImgLink = <div className="taxFileType fileNotFound"></div>;
    }
    if (this.state.transmittalLetterURL) {
      if (this.state.transmittalLetterURL === "File not found") {
        letterToRender = <Fragment>File not found</Fragment>;
        letterImgLink = <div className="taxFileType fileNotFound"></div>;
      } else {
        letterToRender = (
          <Fragment>
            {/* <a
              className="button"
              href={this.state.transmittalLetterURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>{" "} */}
          </Fragment>
        );
        letterImgLink = (
          <a
            href={this.state.transmittalLetterURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="taxFileType pdf"></div>
          </a>
        );
      }
      if (this.state.clientApprovesClick) {
        renderConfirmation = (
          <div>
            <Modal
              size="sm"
              centered
              show={true}
              onHide={this.handleAlertConfirmationCancel.bind(this)}
              aria-labelledby="example-custom-modal-styling-title"
            >
              {/* <Modal.Title>Please Confirm</Modal.Title> */}
              <Modal.Body>
              I am happy for BDO to submit my tax return(s) to HM Revenue & Customs
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="button cancel"
                  onClick={this.handleAlertConfirmationCancel.bind(this)}
                >
                  Cancel
                </Button>
                <Button
                  className="button"
                  onClick={this.handleAlertConfirmationConfirm.bind(this)}
                >
                  Approve
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      } else {
        renderConfirmation = "";
      }
    }
    else
    {
      letterToRender = <Fragment>File not found</Fragment>;
        letterImgLink = <div className="taxFileType fileNotFound"></div>;
    }

    return (
      <div className="taxReview">
        <div className="fileDeck">
          {amendedFound ? (
          <div className="taxContainer_annual taxContainer_borderRight">
          <span className="descriptionYear">{lastTaxReturnYear}</span>
          <header>
            {amendedImgLink}
            <div className="taxFileDetail">
              <h3 className="taxFileYourName">
                {
                  SessionStoreManager.getValueByKey(
                    Constants.currentUserCosmosDBKey
                  ).firstName
                }{" "}
                {
                  SessionStoreManager.getValueByKey(
                    Constants.currentUserCosmosDBKey
                  ).lastName
                }
              </h3>
              <h3 className="taxFileDescription">
                <span className="descriptionType">Amended</span>
                <span className="descriptionType">Tax Return</span>
              </h3>
            </div>
          </header>
          {amendedToRender}
        </div>
          ) : (<div></div>)}
          <div className="taxContainer_annual taxContainer_borderRight">
          <span className="descriptionYear">{taxReturnYear}</span>
            <header>
              {taxReturnImgLink}
              <div className="taxFileDetail">
                <h3 className="taxFileYourName">
                  {
                    SessionStoreManager.getValueByKey(
                      Constants.currentUserCosmosDBKey
                    ).firstName
                  }{" "}
                  {
                    SessionStoreManager.getValueByKey(
                      Constants.currentUserCosmosDBKey
                    ).lastName
                  }
                </h3>
                <h3 className="taxFileDescription">
                  <span className="descriptionType">Tax Return</span>
                </h3>
              </div>
            </header>
            {taxReturnToRender}
          </div>
          <div className="taxContainer_annual">
          <span className="descriptionYear">Supporting document</span>
                  <header>
                    {letterImgLink}
                    <div className="taxFileDetail">
                    <h3 className="taxFileYourName">
                    {
                      SessionStoreManager.getValueByKey(
                        Constants.currentUserCosmosDBKey
                      ).lastName
                    }{" "}
                    {
                      SessionStoreManager.getValueByKey(
                        Constants.currentUserCosmosDBKey
                      ).lastName
                    }
                  </h3>
                  <h3 className="taxFileDescription">
                    <span className="descriptionType">Covering letter</span>
                  </h3>
                </div>
              </header>
              {letterToRender}
              {renderConfirmation}
            </div>
            </div>
        <footer className="buttonDeck">
          {buttons}
        </footer>
        {renderAmendsForm}
        
      </div>
    );
  }
}

export default TaxReturnApproval;
