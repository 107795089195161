import React, { Component, Fragment } from "react";
import Auth from "../../Auth";
import SessionStoreManager from "../utilities/SessionStoreManager";
import {
  baseURL,
  endpoints,
  httpGET,
  appInsightsCode
} from "../../api";
import Constants from "../utilities/Constants";
import ReactGA from "react-ga";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import Modal from "react-bootstrap/Modal";
//import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
//import Button from 'react-bootstrap/Button';


class HistoricalTaxYears extends Component {
    constructor(props) {
      super(props);
      this.state = {
        customerid: undefined,
        transmittalLetterURL: undefined,
        taxreturnURL: undefined,
        isLoading: false,
        taxYear: undefined,
        NavNode: 200,
        taxYearProcess: {},
        showAdditionalDocumentsForm: false,
        additionalFiles: [],
        hasResponse: false,
        showNoResponseWarning: false
      };
      this.baseURL = baseURL;
     
    }
    componentDidMount() {
      ReactGA.pageview(window.location.pathname);
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: appInsightsCode
        }
      });
      appInsights.loadAppInsights();
      appInsights.trackPageView();
      const auth = new Auth();
      if (
        SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey) &&
        SessionStoreManager.getValueByKey(Constants.navRulesKey) &&
        SessionStoreManager.getValueByKey(Constants.navNodesKey)
      ) {
        this.getTaxYearName(
          auth,
          this.props.taxYearId
        );
        this.getTaxYearProcess(auth, this.props.taxYearId, SessionStoreManager.getValueByKey(Constants.currentUserCosmosDBKey).id);
        

      }
    }
    async getAllAdditionalFiles(){
      const auth = new Auth();
      var currentUser = SessionStoreManager.getValueByKey(
        Constants.currentUserCosmosDBKey
      );
      var parameters = currentUser.id +
        "/" +
        this.props.taxYearId;
  
        
      let response = await httpGET(
        endpoints.filter(
          endpoint =>
            endpoint.name === "GetAdditionalDocuments"
        )[0],
        parameters,
        await auth.getToken(),
        true
      );
  
      if (response && response.status === 200) {
        this.setState({
          additionalFiles: response.body,
          showAdditionalDocumentsForm: true
            });
      }
  
    }
    async getTaxYearProcess(auth, taxYearId, customerId){
        var parametersAttachment = customerId + "/" + taxYearId;
      let response = await httpGET(
        endpoints.filter(endpoint => endpoint.name === "GetTaxYearProcess")[0],
        parametersAttachment,
        await auth.getToken(),
        true
      );
  
      if (response && response.status === 200) {
        this.setState({
            taxYearProcess: response.body
        });
        this.getBlobLinkTaxReturn(auth, "TaxReturns");
  
        this.getBlobLinkTransmittalLetter(auth, "CoverLetter");
        this.getResponse(auth);
      }
    }
    async getTaxYearName(auth, taxYearId) {
      var parametersAttachment = taxYearId;
      let response = await httpGET(
        endpoints.filter(endpoint => endpoint.name === "GetTaxYear")[0],
        parametersAttachment,
        await auth.getToken(),
        true
      );
  
      if (response && response.status === 200) {
        this.setState({
          taxYear: response.body[0].fromYear + "/" + response.body[0].toYear.toString().slice(-2)
        });
      }
    }
    async getBlobLinkTaxReturn(auth, foldername) {
      
      var parametersAttachment =
      this.state.taxYearProcess.customerId +
        "/" +
        foldername +
        "/" +
        this.state.taxYearProcess.taxYearId +
        "/true/true";
      let response = await httpGET(
        endpoints.filter(
          endpoint => endpoint.name === "GetAttachmentURLFromBlobContainer"
        )[0],
        parametersAttachment,
        await auth.getToken(),
        false
      );
  
      if (response && response.status === 200) {
        this.setState({
          taxreturnURL: response.body
        });
      }
    }
  
    async getBlobLinkTransmittalLetter(auth, foldername) {
      var parametersAttachment =
      this.state.taxYearProcess.customerId +
        "/" +
        foldername +
        "/" +
        this.state.taxYearProcess.taxYearId +
        "/true/true";
      let response = await httpGET(
        endpoints.filter(
          endpoint => endpoint.name === "GetAttachmentURLFromBlobContainer"
        )[0],
        parametersAttachment,
        await auth.getToken(),
        false
      );
  
      if (response && response.status === 200) {
        this.setState({
          transmittalLetterURL: response.body
        });
      }
    }
    async getResponse(auth) {
   
    
    //Return the questionnaire from the user's process
    var version = this.state.taxYearProcess.questionnaireVersion;
    var parameters =
    this.state.taxYearProcess.taxYearId + "/" + this.state.taxYearProcess.customerId + "/" + version;

    let response = await httpGET(
      endpoints.filter(endpoint => endpoint.name === "GetResponse")[0],
      parameters,
      await auth.getToken(),
      true
    );

    if (response && response.status === 200) {
    
      this.setState({
        hasResponse: true
      });
     
    }
    if (response && response.status === 204) {
      this.setState({
        hasResponse: false
      });
    }
  }
    handleViewAdditionalFiles(){
      this.getAllAdditionalFiles();
      this.setState({
        showAdditionalDocumentsForm: true
      });
    }
    handleCloseAdditionalFiles(){
      this.setState({
        showAdditionalDocumentsForm: false
      });
    }

    handleNoResponseClick(){
      this.setState({
        showNoResponseWarning: true
      });
    }
    handleNoResponseClickClose(){
      this.setState({
        showNoResponseWarning: false
      });
    }
   
    onClick = () => {
      if (this.state.hasResponse === true)
      {
        this.props.handleViewReadOnly(this.props.taxYearId);
      }
      else
      {
        this.handleNoResponseClick();
      }
    }

    onClickFiles= () => {
      this.handleViewAdditionalFiles(this.props.taxYearId);
    }
    
    render() {
      
      var surveyImgLink = "";
      var taxReturnImgLink = "";
      var letterImgLink = "";
      var additionalFilesChange = "";
      surveyImgLink = (
       
        // <a onClick={this.onClick} className="taxFileType file"></a> 
        <a className="taxFileType file"></a> 
        
      );

      additionalFilesChange = (
       
        <a onClick={this.onClickFiles} className="taxFileType folder"></a> 
        
      );
      
      if (this.state.taxreturnURL) {
        //check to see if it exists
        if (this.state.taxreturnURL === "File not found") {
          
          taxReturnImgLink = <div className="taxFileType fileNotFound"></div>;
        } else {          
          taxReturnImgLink = (
            <a
              href={this.state.taxreturnURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="taxFileType pdf"></div>
            </a>
          );
        }
      }
      else
      {
        taxReturnImgLink = <div className="taxFileType fileNotFound"></div>;
      }
      if (this.state.transmittalLetterURL) {
        if (this.state.transmittalLetterURL === "File not found") {
          letterImgLink = (
            
              <div className="taxFileType fileNotFound"></div>
           ) ;
        } else {
          
          letterImgLink = (
            <a
              href={this.state.transmittalLetterURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="taxFileType pdf"></div>
            </a>
          );
        }
        
      }
      else
      {
        letterImgLink = (
            
          <div className="taxFileType fileNotFound"></div>
       ) ;
      }
      var filestoshow = "";
      if(this.state.additionalFiles)
      {
        if(this.state.additionalFiles.length > 0)
        {
          filestoshow = this.state.additionalFiles.map((value, index) => {        
          return (  
             
           
            <div><a key={value.FileName} title={value.FileName} target="_blank" rel="noopener noreferrer" className="fileDownload" href={value.FileLink}>{value.FileName}</a> Last modified: {value.LastModified}</div>
          );
          });
        }
        else{
          filestoshow = (
            
            <h3 className="noFilesFound">No files found</h3>
         ) ;
        }
        
      }
      else
      {
        filestoshow = (
            
          <h3 className="noFilesFound">No files found</h3>
       ) ;
      }
      var additionalFilesModal = "";
  
      if (this.state.showAdditionalDocumentsForm) {
       additionalFilesModal = (
         <div>
           <Modal
             size="md"
             centered
             show={true}
             onHide={this.handleCloseAdditionalFiles.bind(this)}
             aria-labelledby="additionalFiles"
             className="additionalFiles"
           >
             <Modal.Header closeButton>
               Additional Files
             </Modal.Header>
             <Modal.Body>
              {filestoshow}
             </Modal.Body>
             <Modal.Footer>                           
             </Modal.Footer>
           </Modal>
         </div>
       );
   }
   var noResponseModal = "";
   if (this.state.showNoResponseWarning) {
    noResponseModal = (
      <div>
        <Modal
          size="md"
          centered
          show={true}
          onHide={this.handleNoResponseClickClose.bind(this)}
          aria-labelledby="additionalFiles"
          className="additionalFiles"
        >
          <Modal.Header closeButton>
            Questionnaire
          </Modal.Header>
          <Modal.Body>
           No questionnaire found for {this.state.taxYear}
          </Modal.Body>
          <Modal.Footer>                           
          </Modal.Footer>
        </Modal>
      </div>
    );
}
      return (
        <Fragment>
        <header className="historyHead"><h2>Your tax return history</h2></header>
        <Card key={this.props.taxYearId}>
        
          
          <Card.Body>
          <div className="taxArchive">
          <h2>{this.state.taxYear}</h2>
          <div className="statusMessage">{this.props.CurrentTaxYearText}</div>
          <div className="fileDeck">
            <div className="taxContainer_annual">
                    <header>
                      {taxReturnImgLink}
                      <div className="taxFileDetail">
                      <h3 className="taxFileYourName">
                      {
                        SessionStoreManager.getValueByKey(
                          Constants.currentUserCosmosDBKey
                        ).firstName
                      }{" "}
                      {
                        SessionStoreManager.getValueByKey(
                          Constants.currentUserCosmosDBKey
                        ).lastName
                      }
                    </h3>
                    <h3 className="taxFileDescription">
                      <span className="descriptionType">Tax Return</span>
                    </h3>
                  </div>
                </header>
                   
                  </div>
            <div className="taxContainer_annual">
                    <header>
                      {letterImgLink}
                      <div className="taxFileDetail">
                      <h3 className="taxFileYourName">
                      {
                        SessionStoreManager.getValueByKey(
                          Constants.currentUserCosmosDBKey
                        ).lastName
                      }{" "}
                      {
                        SessionStoreManager.getValueByKey(
                          Constants.currentUserCosmosDBKey
                        ).lastName
                      }
                    </h3>
                    <h3 className="taxFileDescription">
                      <span className="descriptionType">Covering letter</span>
                    </h3>
                  </div>
                </header>
               
              </div>
              {/* <div className="taxContainer_annual">
                    <header>
                      {surveyImgLink}
                      <div className="taxFileDetail">
                      <h3 className="taxFileYourName">
                      {
                        SessionStoreManager.getValueByKey(
                          Constants.currentUserCosmosDBKey
                        ).lastName
                      }{" "}
                      {
                        SessionStoreManager.getValueByKey(
                          Constants.currentUserCosmosDBKey
                        ).lastName
                      }
                    </h3>
                    <h3 className="taxFileDescription">
                      <span className="descriptionType">Questionnaire</span>
                    </h3>
                  </div>
                </header>
               
              </div>  */}
              <div className="taxContainer_annual">
                    <header>
                      {additionalFilesChange}
                      <div className="taxFileDetail">
                      <h3 className="taxFileYourName">
                      
                    </h3>
                    <h3 className="taxFileDescription">
                      <span className="descriptionType">Additional Files</span>
                    </h3>
                  </div>
                </header>
               
              </div>
              </div>        
          </div>
          {additionalFilesModal}
          {noResponseModal}
            </Card.Body>
         
       
          </Card>
          </Fragment>
      );
    }
  }
  
  export default HistoricalTaxYears;
  
