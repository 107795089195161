import React, { Component } from "react";
import ReactGA from 'react-ga';

class OPTCRedFlagExit extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }
  render() {
    return (<section className="sectionContent"><div className="page">
    <header><h2>OPTC Red Flag Exit</h2></header>  
    <div className="pageContent"></div> 
    </div></section>);
  }
}

export default OPTCRedFlagExit;
